import Vue from "vue";
import Router from "vue-router";
import App from "@/App";
import Ingresa from "@/components/Ingresa";
import Logout from "@/components/Logout";
import Operadora from "@/components/Menu/Operadora"
import Circuitos from "@/components/Servicios/Circuitos"
import Cruceros from "@/components/Servicios/Cruceros"
import Capacitaciones from "@/components/Menu/Capacitaciones"
import Ofertas from "@/components/Menu/Ofertas"
import Error404 from "@/components/404"
import Inicio from "@/components/Inicio"
import EventBus from "./EventBus";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/Ofertas",
      name: "Ofertas",
      component: Ofertas,
      meta: "post"
    },
    {
      path: "/Capacitaciones",
      name: "Capacitaciones",
      component: Capacitaciones,
      meta: "post",
    },
    {
      path: "/Cruceros",
      name: "Cruceros",
      component: Cruceros,
      meta: "post"
    },
    {
      path: "/Circuitos",
      name: "Circuitos",
      component: Circuitos,
      meta: "post",
    },
    {
      path: "/Operadora",
      name: "Operadora",
      component: Operadora,
      meta: "post"
    },
    {
      path: "/Ingresa",
      name: "Ingresa",
      component: Ingresa,
      props: false,
      meta: "post",
    },
    {
      path: "/:user/:pass",
      name: "Inicio",
      component: Inicio,
      props: true,
      meta: "pre"
    },
    {
      path: "/Logout",
      name: "Logout",
      component: Logout,
      meta: "post"
    },
    {
      path: "/404",
      name: "404",
      component: Error404,
      meta: "error"
    }
  ],
});


router.beforeEach((to, from, next) => {
  if(to.name === null){
    return next({name: "404"})
  }else if(to.meta === "post" && EventBus.obtenerUsuario.userName){
    return next()
  }else if(to.meta === "post" && Object. keys(EventBus.obtenerUsuario()). length <= 0){
    return next({name: "404"})
  }else if(to.meta === "error"){
    EventBus.eliminarUsuario()
    return next()
  }else{
    if(to.meta === "post" && EventBus.obtenerUsuario.userName){
      return next({name: "404"})
    }
    return next()
  }
})



export default router;
