<template>
  <div class="main">
  </div>
</template>
<script>
export default {
  metaInfo() {
        return { 
            title: "Contravel | Ingresa",
        }
    },
  components: {
},
};
</script>
<style scoped>
.main {
  margin-top: 20px;
}
</style>
