<template>
  <div class="section">
    <b-modal class="modal trenes-modal" v-model="interruptor">
      <div id="trenes-details"></div>
      <div
        class="trenes-details"
        v-if="trenDetail"
        style="
          width: 98%;
          margin-top: 50px;
          margin-right: 25px;
          margin-left: 0px;
        "
      >
        <div class="card card-details">
          <div class="thumbnail">
            <img alt="server" class="img-left" :src="server + trenDetail.img" />
          </div>
          <div class="img-right">
            <div style="display: flex; padding: 3px; align-items: center">
              <div style="width: 100%">
                <h1>{{ trenDetail.nombre }}</h1>
              </div>
              <div style="width: 100%; display: flex; justify-content: end">
                <div class="author">
                  <img alt="autor" :src="server + trenDetail.img_prom" />
                </div>
              </div>
            </div>
            <div class="separator"></div>
            <p>{{ trenDetail.descripcion }}</p>
            <div class="target-video">
              <iframe
                width="250"
                height="230"
                :src="trenDetail.video"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="video"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="tablero" style="margin: 30px">
        <div
          class="card-trenes"
          v-for="(card, index) in dataTrenes"
          :key="index"
        >
          <div
            class="card"
            @click="toggleDetails(index)"
            :class="{ active: card.expanded }"
          >
            <div class="card-image">
              <img
                :src="server + card.img_prom"
                alt="Image"
                style="width: 250px; height: 250px; padding: 30px"
              />
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">{{ card.nombre }}</p>
                  <p class="subtitle is-6">{{ card.region }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MainService from "@/services/MainServices";
import ServicioService from "@/services/ServicioService";

export default {
  props: ["value"],
  data() {
    return {
      dataTrenes: [],
      trenDetail: null,
      server: null,
    };
  },
  mounted() {
    this.obtenerTrenes();
    this.obtenerServerName();
  },
  methods: {
    async obtenerServerName() {
      this.server = await MainService.obtenerServer();
    },
    async obtenerTrenes() {
      const respuesta = await ServicioService.obtenerTrenes();
      for (const elemento of respuesta) {
        elemento.expanded = false;
      }
      this.dataTrenes = respuesta;
    },

    toggleDetails(index) {
      this.trenDetail = this.dataTrenes[index];
      if (index != 0) {
        let indice = this.dataTrenes.findIndex(
          (objeto) => objeto.id === this.trenDetail.id
        );
        if (indice !== -1) {
          this.dataTrenes.splice(indice, 1);
          this.dataTrenes.unshift(this.trenDetail);
        }
        this.dataTrenes[0].expanded = true;
      } else {
        this.dataTrenes[index].expanded = !this.dataTrenes[index].expanded;
        if (this.dataTrenes[index].expanded === false) {
          this.trenDetail = null;
        }
      }
      for (let i = 0; i < this.dataTrenes.length; i++) {
        if (i != 0) {
          this.dataTrenes[i].expanded = false;
        }
      }
      const scrollDiv = document.getElementById("trenes-details");
      scrollDiv.scrollIntoView({ behavior: "smooth" });
    },
  },
  computed: {
    interruptor: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
.trenes-modal .modal-content {
  max-width: none !important;
  padding: 30px;
  max-height: none;
}

.trenes-modal .modal-background {
  background-color: rgba(255, 255, 255, 0.4) !important;
  backdrop-filter: blur(10px);
}

.trenes-modal .card {
  width: 250px;
  border-radius: 8px;
  box-shadow: 0px 0px 30px #949494;
  margin: 20px;
  transition: all 0.3s ease;
}

.tablero {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 7px;
  margin-top: 10px;
}

.details {
  /* Estilos de los detalles ocultos */
  display: none;
  transition: all 0.3s ease;
}

.details.active {
  /* Estilos de los detalles expandidos */
  display: block;
  opacity: 0.6;
  /* Ajusta el valor según sea necesario */
}

.details.active ~ .card {
  opacity: 0.5;
}

.trenes-modal .card.active {
  opacity: 0.5;
}

.trenes-details h1 {
  padding-top: 15px;
  font-size: 1.7rem;
  color: #4b4b4b;
  font-weight: bolder;
  font-family: "Roboto", sans-serif !important;
}

@import url(https://fonts.googleapis.com/css?family=Roboto);

.trenes-details {
  padding: 20px;
  font-family: "Roboto", sans-serif !important;
}

.s1 {
  position: absolute;
  top: 0;
  font-size: 15rem;
  font-weight: 800;
  text-transform: uppercase;
  color: #3c4447;
}

.s2 {
  font-weight: 800;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 15rem;
  text-transform: uppercase;
  color: #3c4447;
}

.card-details {
  position: relative !important;
  height: 500px !important;
  width: 98% !important;
  background-color: #fff !important;
  -webkit-box-shadow: 10px 10px 93px 0px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 10px 10px 93px 0px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 10px 10px 93px 0px rgba(0, 0, 0, 0.75) !important;
}

.card-details h6 {
  position: absolute;
  left: 30px;
  bottom: 50px;
  font-size: 1.5rem;
  color: #c3c3c3;
}

.thumbnail {
  float: left;
  position: relative;
  left: 30px;
  top: -30px;
  height: 100%;
  width: 530px;
  -webkit-box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 60px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.img-left {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.img-right {
  margin-left: 590px;
  margin-right: 20px;
}

.author {
  background-color: #9ecaff;
  height: 90px;
  width: 90px;
  border-radius: 50px;
  margin: 5px;
}

.author > img {
  padding-top: 5px;
  margin-left: 5px;
  float: left;
  height: 85px;
  width: 80px;
  resize: both;
  overflow: auto;
  border-radius: 50%;
}

.separator {
  margin-top: 10px;
  border: 1px solid #c3c3c3;
}

.separator p {
  text-align: justify;
  padding-top: 10px;
  font-size: 0.95rem;
  line-height: 150%;
  color: #4b4b4b;
}

.target-video {
  display: grid;
  place-items: center;
  margin-top: 3rem !important;
}
</style>