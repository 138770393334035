<template>
  <div
    style="width: 100%"
    class="column is-flex is-flex-direction-column is-align-items-center"
  >
    <b-message
      size="is-small"
      type="is-success"
      auto-close
      v-model="actualizaCapacitacion"
      style="width: 100%"
    >
      {{ msj }}
    </b-message>
    <b-pagination
      size="is-small"
      order="is-centered"
      class="m-0"
      :total="capacitaciones.length"
      v-if="capacitaciones.length > 2"
      :current.sync="current"
      :per-page="2"
      rounded
      @change="cambiarPages(current)"
    >
    </b-pagination>
    <div
      class="card mt-3"
      style="width: 60%"
      v-for="capacitacion in pagesArray"
      :key="capacitacion.id"
    >
      <div class="card-content">
        <div class="content mb-0">
          <p
            class="title is-6 has-text-centered is-family-code"
            style="font-size: 1.2rem !important"
          >
            {{ capacitacion.asunto }}
          </p>
          <div class="columns">
            <div class="column">
              <div class="rows">
                <div class="row mb-2">
                  <b-taglist attached>
                    <b-tag type="is-success" icon="clock-o">
                      Hora de Capacitacion:
                    </b-tag>
                    <b-tag
                      type="is-info is-light has-text-weight-bold is-family-primary"
                    >
                      {{
                        convertirHoraCapacitaciones(
                          new Date(capacitacion.fecha).getHours(),
                          new Date(capacitacion.fecha).getMinutes()
                        )
                      }}
                    </b-tag>
                  </b-taglist>
                </div>
                <div class="row mt-2">
                  <b-taglist class="mr-2 mb-1" attached>
                    <b-tag type="is-link"> Descripcion: </b-tag>
                    <b-tag>
                      {{ capacitacion.descripcion }}
                    </b-tag>
                  </b-taglist>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="rows">
                <div class="row mb-2">
                  <b-taglist mr-2 attached>
                    <b-tag type="is-warning" icon="hourglass-end">
                      Duración
                    </b-tag>
                    <b-tag type="has-text-weight-bold">
                      {{ capacitacion.duracion }}
                    </b-tag>
                  </b-taglist>
                </div>
                <div class="row" v-if="capacitacion.estatus != 'FINALIZADO'">
                  <b-taglist class="mr-2 mb-1" attached>
                    <b-tag type="is-info"> Espacios Disponibles: </b-tag>
                    <b-tag type="has-text-weight-bold">
                      {{ capacitacion.participantes }}
                    </b-tag>
                  </b-taglist>
                </div>
                <div class="row mt-2 is-flex is-justify-content-end">
                  <b-button
                    v-if="
                      capacitacion.estatus === 'ACTIVO' &&
                      (capacitacion.status === null ||
                        capacitacion.status === 'CANCELADO')
                    "
                    @click="verificarCapacitacion(capacitacion)"
                    rounded
                    type="is-info"
                    size="is-small"
                    outlined
                  >
                    Registrarse
                  </b-button>
                  <b-button
                    v-if="capacitacion.estatus === 'FINALIZADO'"
                    rounded
                    type="is-danger"
                    size="is-small"
                    disabled
                  >
                    Capacitación Expirada
                  </b-button>
                  <b-button
                    v-if="
                      capacitacion.estatus === 'ACTIVO' &&
                      capacitacion.status === 'REGISTRADO'
                    "
                    rounded
                    type="is-success"
                    size="is-small"
                    disabled
                  >
                    REGISTRADO
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-collapse
          v-model="openRegistrar"
          v-if="capacitacion.id == checkBcollapse"
          class="mt-3"
        >
          <div class="columns">
            <div class="column">
              <b-field label="Participante" label-position="on-border">
                <b-input
                  placeholder="Ingresa tu Nombre"
                  ref="refParticipante"
                  v-model="participante"
                  size="is-small"
                  required
                  rounded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="E-mail" label-position="on-border">
                <b-input
                  size="is-small"
                  v-model="email"
                  type="email"
                  ref="refEmail"
                  placeholder="Ingresa tu e-amil"
                  required
                  rounded
                >
                </b-input>
              </b-field>
              <b-field class="is-flex is-justify-content-end">
                <b-button
                  @click="
                    registrarse(
                      capacitacion.id,
                      participante,
                      email,
                      capacitacion.id_registro
                    )
                  "
                  size="is-small"
                  type="is-success"
                  rounded
                  outlined
                >
                  Enviar Registro
                </b-button>
              </b-field>
            </div>
          </div>
        </b-collapse>
        <b-message
          size="is-small"
          type="is-danger"
          v-model="checkRegistro"
          v-if="
            capacitacion.id == checkBcollapse &&
            capacitacion.status === 'CANCELADO'
          "
        >
          <div class="columns">
            <div class="column">
              Ya cancelaste esta capacitación: <br />
              <b>Participante: </b>{{ capacitacion.participante }} <br />
              <b>Correo: </b>{{ capacitacion.email }} <br />
            </div>
            <div
              class="column is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
            >
              ¿Deseas reactivarla con estos datos?
              <div class="is-flex is-justify-content-center pt-1">
                <b-button
                  @click="reActivarCapacitacion(capacitacion.id)"
                  class="ml-4"
                  size="is-small"
                  type="is-info"
                  rounded
                  >Si</b-button
                >
                <b-button
                  @click="updateCapacitacion(capacitacion)"
                  class="ml-4"
                  size="is-small"
                  type="is-danger"
                  rounded
                  >No</b-button
                >
              </div>
            </div>
          </div>
        </b-message>
      </div>
    </div>
    <template v-if="capacitaciones.length <= 0">
      <div class="has-text-centered">No hay registros en esta Fecha</div>
    </template>
  </div>
</template>

<script>
import MainService from "@/services/MainServices";
import EventBus from "@/EventBus";

const thisMonth = new Date().getMonth();
export default {
  props: ["value", "date"],
  data() {
    return {
      capacitaciones: [],
      current: 1,
      pagesArray: [],
      checkBcollapse: null,
      participante: null,
      email: null,
      checkEstatus: "",
      checkRegistro: false,
      actualizaCapacitacion: false,
      msj: "",
      openRegistrar: false,
    };
  },
  methods: {
    async obtenerParticipantes(id, participantes) {
      const respuesta = await MainService.obtenerParticipantes(id);
      return participantes - respuesta[0].registrados;
    },
    updateCapacitacion(capacitacion) {
      this.participante = capacitacion.participante;
      this.email = capacitacion.email;
      this.checkBcollapse = capacitacion.id;
      this.checkRegistro = false;
      this.openRegistrar = !this.openRegistrar;
    },
    cambiarPages(page) {
      this.pagesArray = this.capacitaciones.slice(page + page - 2, page + page);
    },
    convertirHoraCapacitaciones(hora, minutos) {
      if (hora <= 9) {
        hora = "0" + hora;
      }
      if (minutos <= 9) {
        minutos = "0" + minutos;
      }
      return hora + ":" + minutos + " hrs";
    },
    async verificarCapacitacion(capacitacion) {
      this.participante = null;
      this.email = null;
      if (this.checkBcollapse != capacitacion.id) {
        this.openRegistrar = false;
      }
      this.checkBcollapse = capacitacion.id;
      if (capacitacion.id_registro == null) {
        this.checkRegistro = false;
        this.openRegistrar = !this.openRegistrar;
      } else {
        this.openRegistrar = false;
        if (capacitacion.status === "CANCELADO") {
          this.openRegistrar = false;
          this.checkRegistro = !this.checkRegistro;
        }
      }
    },
    async registrarse(id, participante, email, id_registro) {
      const fields = ["refParticipante", "refEmail"];
      for (const field of fields) {
        if (!this.$refs[field][0].checkHtml5Validity()) {
          return;
        }
      }
      if (id_registro === null) {
        const agencia = EventBus.obtenerUsuario()["Reference"];
        const respuesta = await MainService.registrarCapacitacion(
          id,
          agencia,
          participante,
          email
        );
        if (respuesta) {
          this.actualizaCapacitacion = !this.actualizaCapacitacion;
          this.msj = "Capacitacion Registrada con exito!";
          this.openRegistrar = false;
          this.interruptor = !this.interruptor;
        }
      } else {
        const respuesta = await MainService.updateDataCapacitacion(
          id_registro,
          participante,
          email
        );
        if (respuesta) {
          this.actualizaCapacitacion = !this.activarCapacitacion;
          this.msj = "Capacitacion Actualizada con exito!";
          this.openRegistrar = false;
          this.interruptor = !this.interruptor;
        }
      }
    },
    async reActivarCapacitacion(id) {
      const agencia = EventBus.obtenerUsuario()["Reference"];
      const respuesta = await MainService.activarCapacitacion(id, agencia);
      if (respuesta > 0) {
        this.actualizaCapacitacion = !this.actualizaCapacitacion;
        this.msj =
          "Se actualizo la capacitacion, favor de verificar seccion MIS CAPACITACIONES";
        this.checkRegistro = false;
        this.interruptor = !this.interruptor;
      } else {
        this.msj = "No se pudo actualizar capacitación";
        this.checkRegistro = false;
      }
    },
    async obtenerCapacitacionPorFecha(date) {
      this.openRegistrar = false;
      date.setHours("00");
      date.setMinutes("00");
      date.setSeconds("00");
      this.current = 1;
      const respuesta = await MainService.obtenerCapacitacionPorFecha(date);
      for (const elemento of respuesta) {
        const participantes = await this.obtenerParticipantes(
          elemento.id,
          elemento.participantes
        );
        elemento.participantes = participantes;
      }
      this.capacitaciones = respuesta;
      this.pagesArray = this.capacitaciones.slice(0, 2);
    },
  },
  mounted() {
    this.obtenerCapacitacionPorFecha(this.date);
  },
  computed: {
    interruptor: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>