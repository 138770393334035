import HttpService from "@/services/HttpService"

const MailService = {
    async sendMailDireccion(usuario, asunto, email, agencia, mensaje){
        return await HttpService.post("/sendDireccion.php", {
            usuario: usuario,
            asunto:asunto,
            email:email,
            agencia:agencia,
            mensaje:mensaje
        })
    },
    async sendMailInfOferta(nombre, agencia, id, message, email){
        return await HttpService.post("/sendInfOferta.php", {
            nombre: nombre,
            agencia: agencia,
            id: id,
            message: message,
            email: email
        })
    },
    async saveMailReembolsos(pasajero, tipo, linea, agencia, motivo, archivo){
        const formdata = new FormData();
        formdata.append("archivo", archivo)
        formdata.append("pasajero", pasajero)
        formdata.append("tipo", tipo)
        formdata.append("linea", linea)
        formdata.append("agencia", agencia)
        formdata.append("motivo", motivo)
        return await HttpService.formdata("/sendReembolso.php", formdata)
    },
    async sendMailVisa(nombre, email, telefono, nacionalidad, residencia, origen, destino, pasaporte, salida, retorno){
        return await HttpService.post('/sendMailVisa.php',{
            nombre: nombre,
            email: email,
            telefono: telefono,
            nacionalidad: nacionalidad,
            residencia: residencia,
            origen:origen,
            destino: destino,
            pasaporte: pasaporte,
            salida: salida,
            retorno: retorno
        })
    }
}

export default MailService