<template>
  <div>
    <nav
      class="navbar menu-bar is-fixed-top"
      style="border-bottom: 2px solid; border-bottom-color: white"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <a @click="NavegarHacia('Operadora')" class="icon-contravel">
          <img
            src="@/assets/Contravel_Operadora_azul.png"
            id="icon"
            style="height: 70px"
            class="navbar-item"
            alt="User Icon"
          />
        </a>

        <a
          role="button"
          @click="show = !show"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="navbarBasicExample"
        class="navbar-menu has-text-centered"
        :class="{ 'is-active': show }"
      >
        <div class="navbar-end">
          <div class="navbar-item">
            <b-button class="menuBoton" @click="NavegarHacia('Operadora')"
              >Menu Principal</b-button
            >
          </div>
          <div class="navbar-item" id="target">
            <div class="rows has-text-white-ter">
              <div class="row">
                <p>{{ data.nameAgency + " - " + data.reference }}</p>
              </div>
              <div clasS="row">
                <p>
                  {{
                    data.firstName + " " + data.lastName1 + " " + data.lastName2
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import MainService from "@/services/MainServices";

export default {
  props: ["value"],
  name: "Header_ingresa",
  components: {},
  data() {
    return {
      show: false,
      url_pagos: null,
      url_comisiones: null,
      server: null,
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.obtenerServer();
    this.obtenerAccesoPago();
    this.obtenerTablaComision();
  },
  methods: {
    NavegarHacia(name) {
      this.$router.push({
        name: name,
      });
      this.show = false;
    },

    async obtenerTablaComision() {
      const respuesta = await MainService.obtenerTablaComisiones();
      this.url_comisiones = respuesta.Ruta + "/" + respuesta.Nombre;
    },
    async obtenerServer() {
      const respuesta = await MainService.obtenerServer();
      this.server = respuesta;
    },
    async obtenerAccesoPago() {
      const respuesta = await MainService.obtenerAccesoPagos(this.data);
      this.url_pagos = respuesta;
    },
  },
};
</script>

<style scoped>
.menuBoton {
  background-color: #1c496ccb;
  color: white;
  border: none;
}
.menuBoton:hover,
.menuBoton:focus {
  color: #1c496ccb;
  background-color: white;
}

.navbar-brand,
.navbar-menu {
  background-color: rgba(248, 150, 23, 0.896);
}

.navbar-item b {
  color: rgb(0, 112, 171);
}

#target {
  margin: 5px;
  background-color: rgba(31, 124, 253, 0.188);
  border-radius: 10px;
}

p {
  font-size: 11pt;
  font-weight: bolder;
}

.navbar-burger {
  margin-top: 7px;
}

.menu .navbar-item div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu .navbar-item p {
  font-size: 0.8em;
  font-weight: normal;
}

.icon-logout {
  color: #1d274a;
}

.icon-logout:hover {
  color: #ffff;
}

.menu-bar a:hover {
  background-color: rgba(244, 244, 244, 0.205);
  border-radius: 7em 7em;
  color: #193e5b;
}

.menu-bar a {
  color: #ffff;
}

.menu-bar .menu-header {
  color: #193e5b;
}

.menu-bar .navbar-brand a:hover {
  background-color: transparent;
}

.navbar-end a:hover,
.navbar-item.has-dropdown:hover .navbar-link,
.navbar-item:focus {
  background-color: transparent;
  border-radius: 7em 7em;
  color: #193e5b;
}
</style>
