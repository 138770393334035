import HttpService from "./HttpService";

const ServicioService = {
    async obtenerCircuitos(){
        return await HttpService.get("/obtenerCircuitos.php")
    },
    async obtenerCruceros(){
        return await HttpService.get("/obtenerCruceros.php")
    },
    async obtenerTrenes(){
        return await HttpService.get("/obtenerTrenes.php")
    },
    async obtenerVisa(){
        return await HttpService.get("/obtenerVisa.php")
    }
}

export default ServicioService