import HttpService from "@/services/HttpService";

const MainService = {
    async obtenerOfertas(){
        return await HttpService.get("/obtenerOfertas.php")
    },
    async obtenerReportes(agencia){
        return await HttpService.post("/obtenerReportes.php", {agencia: agencia})
    },
    async obtenerMSI(){
        return await HttpService.get("/obtenerMSI.php")
    },
    async updateComunicados(date){
        return await HttpService.post("/updateComunicados.php", {date})
    },
    async obtenerCatalogo(){
        return await HttpService.get("/obtenerCatalogo.php")
    },
    async obtenerPorPais(pais, tipo){
        return await HttpService.post("/obtenerPorPais.php",{
            pais: pais,
            tipo:tipo
        })
    },
    async obtenerPais(tipo,pais){
        return await HttpService.post("/obtenerPais.php",{
            tipo:tipo,
            pais: pais})
    },
    async obtenerPorRegion(tipo,region){
        return await HttpService.post("/obtenerPorRegion.php", {
            tipo: tipo,
            region: region
        })
    },
    async actualizarPaquetes(date){
        return await HttpService.post("/actualizarPaquetes.php", date)
    },
    async obtenerHtml(){
        return await HttpService.get("/obtenerHtml.php")
    },
    async obtenerDataPagos(tipo){
        return await HttpService.post("/obtenerDataPagos.php", {tipo: tipo})
    },
    async obtenerDataProcedimientos(procedimiento){
        return await HttpService.post("/obtenerDataProcedimientos.php", {procedimiento: procedimiento})
    },
    async obtenerMods(){
        return await HttpService.get("/obtenerMods.php")
    },
    async  obtenerEventoPorFecha(fecha){
        return await HttpService.post("/obtenerEvento.php",{
            fecha: fecha
        })
    },
    async obtenerEventos(){
        return await HttpService.get("/obtenerEventos.php")
    },
    async obtenerFormasPagos(){
        return await HttpService.get("/obtenerFormasPagos.php")
    },
    async obtenerProcedimientos(){
        return await HttpService.get("/obtenerProcedimientos.php")
    },
    async obtenerDatoArea(area){
        return await HttpService.post("/obtenerDirectorioArea.php", {
            area:area})
    },
    async obtenerAreas(){
        return await HttpService.get("/obtenerAreas.php")
    },
    async obtenerTablaComisiones(){
        return await HttpService.get("/obtenerTablaComisiones.php")
    },
    async prueba(){
        return await HttpService.post("/prueba.php")
    },
    async obtenerServer(){
        return await HttpService.get("/obtenerNombreServidor.php")
    },
    async obtenerAccesos(user){
        return await HttpService.post("/obtenerAccesos.php",{
            user: user
        })
    },
    async obtenerAccesoPagos(user){
        return await HttpService.post("/obtenerAccesoPagos.php", {
            user: user
        })
    },
    async obtenerComunicados(tag){
        return await HttpService.post("/obtenerComunicados.php", {tag})
    },
    async obtenerTags(){
        return await HttpService.get("/obtenerTags.php")
    },
    async obtenerModulos(){
        return await HttpService.get("/obtenerModulos.php")
    },
    async obtenerUltimasOfertas(){
        return await HttpService.get("/obtenerUltimasOfertas.php")
    },
    async obtenerCapacitaciones(date){
        return await HttpService.post("/obtenerCapacitaciones.php", {date: date})
    },
    async obtenerCapacitacionPorFecha(date){
        return await HttpService.post("/obtenerCapacitacionPorFecha.php", {date:date})
    },
    async obtenerMisCapacitaciones(agencia){
        return await HttpService.post("/obtenerMisCapacitaciones.php", {agencia: agencia})
    },
    async cancelarRegistro(id){
        return await HttpService.post("/cancelarRegistroCapacitaciones.php", {id: id})
    },
    async verificarCapacitacion(id, agencia){
        return await HttpService.post("/verificarRegistro.php", {id: id, agencia: agencia})
    },
    async activarCapacitacion(id, agencia){
        return await HttpService.post("/activarCapacitacion.php", {id: id, agencia: agencia})
    },
    async registrarCapacitacion(id, agencia, participante, email){
        return await HttpService.post("/registrarCapacitacion.php", {
            id:id,
            agencia: agencia,
            participante: participante,
            email: email})
    },
    async updateDataCapacitacion(id_registro, participante, email){
        return await HttpService.post("/updateDataCapacitacion.php", {
            id_registro:id_registro,
            participante: participante,
            email: email})
    },
    async obtenerParticipantes(id){
        return await HttpService.post("/obtenerParticipantes.php", {
            id: id
        })
    },
    async obtenerCarrusel(){
        return await HttpService.get("/obtenerCarrusel.php")
    }
};

export default MainService;