<template>
  <div>
    <b-modal v-model="interruptor" width="80%" animation="zoom-out">
      <div class="modal-card" style="width: 100%">
        <header
          class="modal-card-head has-text-centered"
          style="background-color: #1b7fb4"
        >
          <p class="modal-card-title has-text-white">Asistencia de Viajes</p>
        </header>
        <section class="modal-card-body">
          <div
            class="section"
            style="background-color: #007dcc; padding-top: 20px"
          >
            <div class="columns">
              <div class="column">
                <p class="modal-card-title has-text-white has-text-centered">
                  Seleccione el Producto:
                </p>
              </div>
              <div class="column">
                <b-field>
                  <b-select
                    expanded
                    placeholder="Seleciona un producto"
                    icon="globe"
                    size="is-small"
                    v-model="seguro"
                  >
                    <option value="6732">International v.3</option>
                    <option value="6738">Mundial v.3</option>
                    <option value="6742">Discover v.3</option>
                    <option value="6747">New Business v.3</option>
                    <option value="9647">CONTRAVEL TW</option>
                    <option value="812">TW México</option>
                    <option value="8634">MEXICO LINDO</option>
                    <option value="6730">International Familiar v.3</option>
                    <option value="6736">Mundial Familiar v.3</option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <iframe
              title="tw_global"
              :src="
                'https://www.twglobalprotection.com/seguro_viaje/ag_1001/prod_' +
                seguro
              "
              style="height: 1000px; width: 100%"
            ></iframe>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      seguro: "6732",
    };
  },
  computed: {
    interruptor: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>