<template>
  <section class="hero is-fullheight has-background-light">
    <div class="hero-body" style="justify-content: center">
      <div class="container has-text-centered">
        <div
          class="columns is-flex is-flex-direction-column is-align-items-center"
        >
          <div class="column">
            <img
              src="@/assets/logo_contravel_dark.png"
              id="icon"
              style="height: 120px"
              class="navbar-item"
              alt="User Icon"
            />
          </div>
        </div>
        <h1 class="title is-size-1 has-text-orange">404</h1>
        <h2 class="subtitle is-size-4 has-text-orange">Página no encontrada</h2>
        <p class="is-size-5 has-text-orange-dark">
          Lo sentimos, la página que estás buscando no existe. Regresar a la
          otra pagina
        </p>
        <b-button
          @click="openURL()"
          type="is-info"
          rounded
          outlined
          class="mt-4 has-background-blue has-text-white"
        >
          Volver a la página de inicio
        </b-button>
      </div>
    </div>
  </section>
</template>
  
<script>
export default {
  name: "NotFound",
  created() {
    this.eliminarUser();
  },
  methods: {
    openURL() {
      window.location.assign("https://www.contravel.com.mx");
    },
    eliminarUser() {
      this.navegarHacia("404");
    },
    navegarHacia(nombreRuta) {
      this.$router.push({ name: nombreRuta });
    },
  },
};
</script>
  
<style scoped>
.hero {
  background-color: #f5f5f5;
}

.has-text-orange {
  color: #ff6600;
}

.has-text-orange-dark {
  color: #cc5500;
}

.has-background-blue {
  background-color: #007bff;
  border-color: #007bff;
  color: #007bff !important;
}

.has-background-blue:hover {
  color: white !important;
}
</style>