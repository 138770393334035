<template>
  <footer class="footer" style="background-color: #091b28">
    <div class="content has-text-centered pt-1">
      <div class="columns pl-6">
        <div class="column is-one-third pl-6 pt-0">
          <a href="https://www.contravel.com.mx" target="_blank">
            <figure class="image is-128x128" style="height: 78px; width: 140px">
              <img alt="Logo_operadora" :src="require('@/assets/Contravel_Operadora_white.png')" />
            </figure>
          </a>
          <p class="is-size-7 has-text-justified has-text-white">
            <b>MISION</b><br>
            Proporcionar servicios terrestres de calidad a
            nuestras agencias afiliadas con productos
            diferenciados <br>
            <b>VISION</b><br>
            Ser la primera opción en servicios terrestres
            de nuestras agencias afiliadas y no afiliadas,
            manteniendo un crecimiento constante en la
            calidad de nuestro servicios y productos.
          </p>
        </div>
        <div class="column is-one-third pl-6 is-flex is-flex-direction-column is-align-items-start has-text-left">
          <h4 class="title is-4 has-text-white">Contactanos</h4>
          <div class="content is-size-7 has-text-white">
            <ul>
              <li>
                <b-icon icon="envelope-o" size="is-small" />
                operadora@contravel.com.mx
              </li>
              <li>
                <b-icon icon="phone" size="is-small" />
                5536-1790 Opción 2
              </li>
              <li>
                <b-icon icon="map-marker" size="is-small" />
                Insurgentes Sur No. 1337, Piso 1. Col. Insurgentes Mixcoac, C.P.
                03920, Ciudad de México
              </li>
            </ul>
          </div>
        </div>
        <div class="column is-one-third pl-6 is-flex is-flex-direction-column is-align-items-start has-text-left">
          <h4 class="title is-4 has-text-white">Horarios de Atención</h4>
          <b-table class="tableHorario" :data="tablaHorario" style="background-color: #aaa">
            <b-table-column label="Lunes - Viernes" v-slot="props">
              {{ props.row.Esemana }}
            </b-table-column>

            <b-table-column label="Sabados" v-slot="props">
              {{ props.row.Sabados }}
            </b-table-column>
          </b-table>
        </div>
      </div>
      <div class="columns pt-5">
        <div
          class="column is-flex is-flex-direction-column is-justify-content-center is-align-content-end is-align-items-center">
          <a href="https://www.contravel.com.mx" target="_blank">
            <p class="politica is-size-8 has-text-justified has-text-white">
              <u>Aviso de Politica y Privacidad</u>
            </p>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      tablaHorario: [
        { Esemana: "9:00 am - 20:00 pm", Sabados: "9:00 am - 14:00 pm" },
      ],
    };
  },
};
</script>
<style>
.tableHorario table,
.tableHorario table thead td,
.tableHorario table thead th {
  border-width: 2px !important;
  color: white !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.tableHorario td {
  border-width: 2px !important;
}

.tableHorario.b-table {
  background-color: rgba(255, 255, 255, 0) !important;
}

.footer {
  background-color: #fafafa;
  padding: 3rem 1.5rem 2rem !important;
}

.politica:hover {
  color: #eb8d01 !important;
}

@media only screen and (max-width: 768px) {
  .tableHorario.b-table {
    background-color: rgb(0, 0, 0) !important;
  }

  .footer {
    background-color: rgb(0, 0, 0) !important;
  }
}
</style>