<template>
    <div class="section">
        <b-modal class="modal modal-priv" v-model="interruptor">
            <div class="scroll" style="height: 100%; width: 100%;">
                <div class="is-flex is-flex-direction-column is-align-items-center">
                    <div class="column">
                        <div class="box">
                            <div class="columns p-0">
                                <div class="column is-flex is-justify-content-center is-align-items-center p-0">
                                    <b-button icon-left="arrow-left" size="is-small" @click="pages--"
                                        :disabled="pages <= 1"></b-button>
                                    <b-tag class="p-4">{{ pages }} / {{ numpages }}</b-tag>
                                    <b-button icon-right="arrow-right" size="is-small" @click="pages++"
                                        :disabled="pages == numpages"></b-button>
                                </div>
                            </div>
                            <pdf class="is-flex is-justify-content-center pt-0 mt-0" v-if="ruta != null" :src="ruta"
                                :page="pages">
                                <template slot="loading">
                                    <div class="section is-flex is-justify-content-center">
                                        Cargando Contenido, espere un momento....
                                    </div>
                                </template>
                            </pdf>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import MainService from '@/services/MainServices'
import pdf from "pdfvuer"
export default {
    props: ['value'],
    components: {
        pdf
    },
    data() {
        return {
            height: '',
            pages: 1,
            numpages: 0,
            ruta: null,
            server: null
        }
    },
    computed: {
        interruptor: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    mounted() {
        this.obtenerServidorbackend()
    },
    methods: {
        async obtenerServidorbackend() {
            this.server = await MainService.obtenerServer()
            this.ruta = this.server + '/softw/contravel/otros/' + "Movilidad.pdf"
            this.obtenerNumPaginas()
        },
        async obtenerNumPaginas() {
            const respuesta = await pdf.createLoadingTask(this.ruta)
            this.numpages = respuesta._pdfInfo.numPages
        }
    }
}
</script>
<style>
.modal-priv .modal-content{
    max-width: 100% !important;
}
.scroll {
    overflow-y: scroll;
    scrollbar-color: #0a4b9500 #c2d2e400;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 0px;
    background-color: #f5f5f500;
}

::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f500;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(85, 85, 85, 0);
}

@-webkit-keyframes fade-in-up {
    0% {
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

/* El texto de contenido donde flota el video */
@keyframes fade-in-up {
    0% {
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

/* Contenedor principal del video */
.contenedor_video {
    padding: 45px;
    display: block;
    margin: 0 auto;
}

/* El subcontenedor con la clase .video con el elemento HTML video */
.video video {
    max-width: 100%;
    max-height: 100%;
    width: 750px;
    height: 450px;
    background-color: #e6e5e6;
}

/* Contenedor del video flotante o sticky */
.videoflotante {
    position: fixed;
    top: calc(100vh - 30vh);
    right: 20px;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    width: 345px;
    height: 200px;
    -webkit-animation: fade-in-up .25s ease forwards;
    animation: fade-in-up .25s ease forwards;
    border: 5px solid #888d92;
    padding: 0%;
}

/* Cuando cerramos el video flotante, volvemos su posición al estado inicial */
.videoflotante2 {
    position: initial;
}

/* Botón cerrar el video flotante */
.btn_cerrar {
    font-size: 30px;
    position: absolute;
    margin-top: 405px;
    margin-left: 230px;
    cursor: pointer;
}

/* Oculta el botón para cerrar el video flotante */
.btn_cerrar_cls {
    display: none;
}

/* Propiedades CSS para que se ajuste a los dispositivos móviles con max-width de 360px */
@media(max-width: 900px) {
    .video video {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 220px;
        background-color: #e6e5e6;
    }

    .videoflotante {
        width: 245px;
        height: 135px;
        top: calc(100vh - 25vh);
    }

    .btn_cerrar {
        margin-top: -49px;
        margin-left: 220px;
    }

}

@media (max-height: 550px) {
    .videoflotante {
        top: calc(100vh - 32vh);
    }

}

@media (max-height: 617px) {
    .videoflotante {
        top: calc(100vh - 35vh);
    }

}
</style>