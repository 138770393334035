import HttpService from "@/services/HttpService";

const LoginServices = {
    async CloseLogin(){
        return await HttpService.post("/Logout.php");
    },
    async obtenerSersion(){
        return await HttpService.post("/obtenerSesion.php");
    },
    async Login(user, cifrado){
        return await HttpService.post("/Login.php", {
            user: user,
            cifrado: cifrado
        })
    },
     async obtenerInfoUser(params){
        return await HttpService.post("/obtenerInfoSesion.php", {
            params: params
        })
     }
}
export default LoginServices;