<template>
  <div class="section mt-3 mb-0 mt-0 mr-3 ml-3">
    <div style="width: 100%">
      <div class="titulo is-flex is-align-items-center pl-6 pb-0">
        <div class="ofertas is-flex is-align-items-center">
          <h1 class="subtitle is-3 pr-2">
            <strong>Promociones que no te puedes perder!</strong>
          </h1>
        </div>
      </div>
    </div>
    <div class="column is-flex is-justify-content-center">
      <b-pagination
        v-if="ofertas.length > 2"
        size="is-small"
        order="is-centered"
        style="width: 20%"
        :total="ofertas.length"
        :current.sync="current"
        :per-page="2"
        rounded
        @change="cambiarPages(current)"
      >
      </b-pagination>
    </div>
    <div
      class="columns is-centered"
      v-for="(item, index) in 2"
      v-if="ofertas.length <= 0 && isEmpty == false"
      :key="item"
    >
      <div class="column is-one-fifth" v-for="(item, index) in 4" :key="index">
        <div class="box">
          <figure class="media-center">
            <p class="image">
              <b-skeleton width="100%" height="200"></b-skeleton>
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <b-skeleton active></b-skeleton>
                <b-skeleton height="80px" width="100%"></b-skeleton>
              </p>
            </div>
            <nav class="level is-mobile">
              <div class="level-left">
                <a class="level-item">
                  <b-skeleton width="100"></b-skeleton>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div
      class="column is-two-fifths"
      v-for="(data, index) in oferta"
      v-bind:key="index"
    >
      <div
        class="column is-two-fifths"
        v-for="(data, index) in oferta"
        :key="index"
      >
        <b-tag
          id="oferta"
          type="is-danger"
          size="is-medium"
          style="position: absolute; top: 100; z-index: 4"
        >
          <b>¡OFERTA!</b>
        </b-tag>
        <div class="box p-2 ofert-box" style="height: 100%">
          <div class="columns">
            <div class="column is-two-fifths">
              <b-image :src="server + data.imagen"></b-image>
            </div>
            <div class="column is-flex is-flex-direction-column">
              <div class="rows" style="flex: 1 1 auto">
                <div class="row is-flex is-justify-content-center">
                  <h5
                    class="title is-5 m-0 has-text-centered m-1"
                    style="color: black; font-weight: 650"
                  >
                    {{ data.titulo }}
                  </h5>
                </div>
                <div class="row is-flex is-justify-content-center m-2">
                  <b-tag
                    style="background-color: transparent; color: red"
                    size="is-medium"
                    icon="certificate"
                    icon-type="is-warning"
                  >
                    <b> DESDE: {{ data.precio }}</b></b-tag
                  >
                </div>
                <div class="row">
                  <div class="columns">
                    <div class="column is-flex is-justify-content-right pr-0">
                      <b-tag icon="clock-o" type="is-primary">Salidas:</b-tag>
                    </div>
                    <div class="column is-flex is-flex-direction-column pl-0">
                      <b-tag
                        class="ml-2"
                        style="font-size: 9pt; width: 70%"
                        size="is-small"
                        type="is-primary is-light"
                        v-for="(salida, id) in data.salidas"
                        :key="id"
                        >{{ salida }}</b-tag
                      >
                    </div>
                  </div>
                </div>
              </div>
              <footer>
                <div class="columns">
                  <div
                    class="column is-flex is-flex-direction-column is-justify-content-start"
                  >
                    <b-tag style="background: transparent; font-weight: bolder"
                      >Duracion: {{ data.duracion }}</b-tag
                    >
                  </div>
                  <div class="column is-flex is-justify-content-end">
                    <b-field grouped>
                      <a id="vermas" @click="NavegarHacia(data.id)"
                        >Ver más...</a
                      >
                    </b-field>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="ofertas.length <= 0">
      <div class="has-text-centered">
        ¡Por el momento no tenemos circuitos, vuelve pronto!
      </div>
    </template>
    <div class="column is-flex is-justify-content-center">
      <b-pagination
        type="is-warning"
        v-if="ofertas.length > 2"
        size="is-small"
        order="is-centered"
        style="width: 20%"
        :total="ofertas.length"
        :current.sync="current"
        :per-page="2"
        rounded
        @change="cambiarPages(current)"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import MainService from "@/services/MainServices";

export default {
  data() {
    return {
      ofertas: [],
      pageArray: [],
      current: 1,
      isEmpty: true,
      server: null,
    };
  },
  mounted() {
    this.obtenerOfertas();
    this.obtenerServidorbackend();
  },
  methods: {
    async obtenerOfertas() {
      const respuesta = await MainService.obtenerOfertas();
      console.log(respuesta);
      if (respuesta.length > 0) {
        this.isEmpty = false;
      }
      for (const elemento of respuesta) {
        elemento.salidas = elemento.salidas.split(",");
      }

      this.ofertas = this.dividir(respuesta, 3);
      this.pageArray = this.ofertas.slice(0, 2);
    },
    cambiarPages(page) {
      this.pageArray = this.ofertas.slice(page + page - 2, page + page);
    },
    dividir(arr, size) {
      let chunk = [],
        i; // declara array vacio e indice de for
      for (
        i = 0;
        i <= arr.length - 1;
        i += size // loop que recorre el array
      )
        chunk.push(arr.slice(i, i + size));
      return chunk;
    },
    async obtenerServidorbackend() {
      this.server = await MainService.obtenerServer();
    },
    NavegarHacia(props) {
      window.open(
        "//" + window.location.host + "/detalles/#/" + props,
        "_blank"
      );
    },
  },
};
</script>
<style scoped>
#vermas {
  color: rgb(0, 112, 171);
  font-weight: bolder;
}

#vermas:hover {
  color: rgb(235, 141, 0);
}

#oferta {
  transform: rotate(-20deg) translateX(-15px);
}

.ofert-box {
  background: linear-gradient(
    to left,
    white 0%,
    rgb(255, 255, 255) 50%,
    #f7df01 90%,
    #f7df01 100%
  );
}
</style>