<template>
  <div>
    <h1 class="is-size-1">Estamos cerrando sesión</h1>
    <p>Espera...</p>
    <p>
      Si estás viendo esto por mucho tiempo, sería bueno refrescar la página.
      Esto suele pasar cuando no cierras sesión antes de cerrar el navegador
    </p>
  </div>
</template>

<script>
import LoginServices from "@/services/LoginServices";
import EventBus from "@/EventBus";

export default {
  name: "Logout",
  async mounted() {
    EventBus.eliminarUsuario();
    await LoginServices.CloseLogin();
    EventBus.$emit("navegarHacia", "Agente");
  },
};
</script>
