<template>
  <div class="section mt-3">
    <p class="panel-heading is-flex is-justify-content-center mb-4">
      Capacitaciones
    </p>
    <div class="columns">
      <div class="column">
        <b-button
          :icon-right="icon"
          class="m-4"
          @click="abrirMisCapacitaciones()"
        >
          Mis Capacitaciones
        </b-button>
        <b-collapse v-model="openCapacitacion">
          <MisCapacitaciones
            v-if="openCapacitacion"
            v-model="interruptor"
            :key="interruptor"
          ></MisCapacitaciones>
        </b-collapse>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <span>
          <b-datepicker
            class="datepicker-pagos"
            inline
            v-model="date"
            :events="events"
            size="is-small"
            @input="accionComponente(date)"
          >
          </b-datepicker>
        </span>
      </div>
      <div
        class="column is-flex is-flex-direction-column is-align-items-center"
      >
        <ListaCapacitacion
          ref="ListaCapacitaciones"
          v-model="interruptor"
          :date="date"
          :key="interruptor"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MainService from "@/services/MainServices";
import MisCapacitaciones from "@/components/Menu/MisCapacitaciones.vue";
import ListaCapacitacion from "@/components/Menu/CapacitacionesList.vue";

export default {
  components: {
    MisCapacitaciones,
    ListaCapacitacion,
  },
  data() {
    return {
      openCapacitacion: false,
      icon: "arrow-up",
      interruptor: true,
      typeEvent: null,
      events: [],
      date: new Date(),
    };
  },
  methods: {
    accionComponente(date) {
      this.$refs.ListaCapacitaciones.obtenerCapacitacionPorFecha(date);
    },
    async obtenerCapacitaciones(date) {
      const respuesta = await MainService.obtenerCapacitaciones(date);
      if (respuesta) {
        for (const respuestaItem of respuesta) {
          const dateEvent = new Date(respuestaItem.fecha);
          this.typeEvent = "is-danger";
          if (dateEvent > this.date) {
            this.typeEvent = "is-info";
          }
          const fecha = {
            date: dateEvent,
            type: this.typeEvent,
          };
          this.events.push(fecha);
        }
      }
    },
    abrirMisCapacitaciones() {
      this.openCapacitacion = !this.openCapacitacion;
      if (this.openCapacitacion === true) {
        this.icon = "arrow-down";
      } else {
        this.icon = "arrow-up";
      }
    },
  },
  mounted() {
    this.obtenerCapacitaciones(this.date);
    this.accionComponente(this.date);
  },
};
</script>
