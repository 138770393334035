<template>
  <div class="section m-4 mt-0" style="max-height: none">
    <div
      class="section is-flex is-flex-direction-column is-justify-content-center is-align-items-center pt-2 pb-0 pr-0"
    >
      <div style="width: 100%">
        <div class="titulo is-flex is-align-items-center p-3 pr-0 pb-3">
          <div class="ofertas is-flex is-align-items-center">
            <h1 class="subtitle is-3 pr-0">Descubre nuestros</h1>
            <h1 class="title pl-3">Servicios!</h1>
          </div>
        </div>
      </div>
      <ul class="operadora hex2">
        <li>
          <div class="hexagon-menu clear">
            <div class="hexagon-item">
              <div class="hex-item">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <a class="hex-content" @click="NavegarHacia('Circuitos')">
                <span class="hex-content-inner">
                  <span
                    class="subtitle"
                    style="
                      color: #006991;
                      font-size: 1rem;
                      font-family: Arial Black;
                    "
                    >Circuitos</span
                  >
                </span>
                <svg
                  viewBox="6 0 88 98"
                  height="240"
                  width="174"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <pattern
                    id="imgCircuitos"
                    patternUnits="userSpaceOnUse"
                    width="200"
                    height="300"
                  >
                    <image
                      :href="
                        require('@/assets/Contravel Archivos/servicios/circuitos.png')
                      "
                      x="13"
                      y="10"
                      width="-60"
                      height="60"
                    ></image>
                  </pattern>
                  <polygon
                    points="50 -1 95 25 95 75 50 101 5 75 5 25"
                    fill="url(#imgCircuitos)"
                  ></polygon>
                </svg>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div class="hexagon-menu clear">
            <div class="hexagon-item">
              <div class="hex-item">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <a class="hex-content" @click="NavegarHacia('Cruceros')">
                <span class="hex-content-inner">
                  <span
                    class="subtitle"
                    style="
                      color: #006991;
                      font-size: 1rem;
                      font-family: Arial Black;
                    "
                    >Cruceros</span
                  >
                </span>
                <svg
                  viewBox="6 0 88 98"
                  height="240"
                  width="174"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <pattern
                    id="imgCruceros"
                    patternUnits="userSpaceOnUse"
                    width="200"
                    height="300"
                  >
                    <image
                      :href="
                        require('@/assets/Contravel Archivos/servicios/cruceros.png')
                      "
                      x="16"
                      y="9"
                      width="-50"
                      height="65"
                    ></image>
                  </pattern>
                  <polygon
                    points="50 -1 95 25 95 75 50 101 5 75 5 25"
                    fill="url(#imgCruceros)"
                  ></polygon>
                </svg>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div class="hexagon-menu clear">
            <div class="hexagon-item">
              <div class="hex-item">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <a class="hex-content" @click="modalTrenes = !modalTrenes">
                <span class="hex-content-inner">
                  <span
                    class="subtitle"
                    style="
                      color: #006991;
                      font-size: 1rem;
                      font-family: Arial Black;
                    "
                    >Trenes</span
                  >
                </span>
                <svg
                  viewBox="6 0 88 98"
                  height="240"
                  width="174"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <pattern
                    id="imgTrenes"
                    patternUnits="userSpaceOnUse"
                    width="200"
                    height="300"
                  >
                    <image
                      :href="
                        require('@/assets/Contravel Archivos/servicios/trenes.png')
                      "
                      x="17"
                      y="10"
                      width="-60"
                      height="63"
                    ></image>
                  </pattern>
                  <polygon
                    points="50 -1 95 25 95 75 50 101 5 75 5 25"
                    fill="url(#imgTrenes)"
                  ></polygon>
                </svg>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div class="hexagon-menu clear">
            <div class="hexagon-item">
              <div class="hex-item">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <a class="hex-content" @click="modalBlack = !modalBlack">
                <span class="hex-content-inner">
                  <span
                    class="subtitle"
                    style="
                      color: #006991;
                      font-size: 1rem;
                      font-family: Arial Black;
                    "
                    >Servicios Privados</span
                  >
                </span>
                <svg
                  viewBox="6 0 88 98"
                  height="240"
                  width="174"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <pattern
                    id="imgBlack"
                    patternUnits="userSpaceOnUse"
                    width="200"
                    height="300"
                  >
                    <image
                      :href="
                        require('@/assets/Contravel Archivos/servicios/black.png')
                      "
                      x="17"
                      y="10"
                      width="-60"
                      height="64"
                    ></image>
                  </pattern>
                  <polygon
                    points="50 -1 95 25 95 75 50 101 5 75 5 25"
                    fill="url(#imgBlack)"
                  ></polygon>
                </svg>
              </a>
            </div>
          </div>
        </li>
      </ul>
      <ul class="operadora hex1">
        <li @click="modalAutos != modalAutos">
          <div class="hexagon-menu clear">
            <div class="hexagon-item">
              <div class="hex-item">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <a class="hex-content" @click="modalAutos = !modalAutos">
                <span class="hex-content-inner">
                  <span
                    class="subtitle"
                    style="
                      color: #006991;
                      font-size: 1rem;
                      font-family: Arial Black;
                    "
                    >Autos</span
                  >
                </span>
                <svg
                  viewBox="6 0 88 98"
                  height="240"
                  width="174"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <pattern
                    id="imgAutos"
                    patternUnits="userSpaceOnUse"
                    width="200"
                    height="300"
                  >
                    <image
                      :href="
                        require('@/assets/Contravel Archivos/servicios/autos.png')
                      "
                      x="15"
                      y="9"
                      width="-60"
                      height="63"
                    ></image>
                  </pattern>
                  <polygon
                    points="50 -1 95 25 95 75 50 101 5 75 5 25"
                    fill="url(#imgAutos)"
                  ></polygon>
                </svg>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div class="hexagon-menu clear">
            <div class="hexagon-item">
              <div class="hex-item">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <a
                class="hex-content"
                @click="abrirAsistencia()"
              >
                <span class="hex-content-inner">
                  <span
                    class="subtitle"
                    style="
                      font-size: 1rem;
                      color: #006991;
                      font-family: Arial Black;
                    "
                    >Asistencia de viaje</span
                  >
                </span>
                <svg
                  viewBox="6 0 88 98"
                  height="240"
                  width="174"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <pattern
                    id="imgAsistencia"
                    patternUnits="userSpaceOnUse"
                    width="120"
                    height="200"
                  >
                    <image
                      :href="
                        require('@/assets/Contravel Archivos/servicios/asistencia.png')
                      "
                      x="13"
                      y="12"
                      width="-60"
                      height="58"
                    ></image>
                  </pattern>
                  <polygon
                    points="50 -1 95 25 95 75 50 101 5 75 5 25"
                    fill="url(#imgAsistencia)"
                  ></polygon>
                </svg>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div class="hexagon-menu clear">
            <div class="hexagon-item">
              <div class="hex-item">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <a class="hex-content" @click="cambiarCss()">
                <span class="hex-content-inner">
                  <span
                    class="subtitle"
                    style="
                      font-size: 1.1rem;
                      color: #006991;
                      font-family: Arial Black;
                    "
                    >Otros</span
                  >
                </span>
                <svg
                  viewBox="6 0 88 98"
                  height="240"
                  width="174"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <pattern
                    id="imgOtros"
                    patternUnits="userSpaceOnUse"
                    width="200"
                    height="300"
                  >
                    <image
                      :href="
                        require('@/assets/Contravel Archivos/servicios/otros.png')
                      "
                      x="9"
                      y="6"
                      width="-60"
                      height="65"
                    ></image>
                  </pattern>
                  <polygon
                    points="50 -1 95 25 95 75 50 101 5 75 5 25"
                    fill="url(#imgOtros)"
                  ></polygon>
                </svg>
              </a>
            </div>
          </div>
        </li>
      </ul>
      <Autos v-model="modalAutos" v-if="modalAutos" />
      <Asistencia v-model="modalAsistencia" v-if="modalAsistencia" />
      <Black v-model="modalBlack" v-if="modalBlack" />
      <Trenes v-model="modalTrenes" v-if="modalTrenes" />
      <Visa v-model="modalVisa" v-if="modalVisa" />
    </div>
    <div :class="'section pt-6 otros' + otros">
      <div class="columns">
        <div
          class="column otros-child is-flex is-flex-direction-column is-justify-content-center is-align-content-center is-align-items-center"
          @click="obtenerRuta('CBX.pdf')"
        >
          <b-image
            class="image-otros"
            :src="require('@/assets/Contravel Archivos/servicios/blue.jpg')"
            alt="The Buefy Logo"
            style="width: 150px"
            responsive
          ></b-image>
          <b class="has-text-centered mt-4">Blue Ribbon Bags</b>
        </div>
        <div
          class="column otros-child is-flex is-flex-direction-column is-justify-content-center is-align-content-center is-align-items-center"
          @click="obtenerRuta('deportivos.pdf')"
        >
          <b-image
            class="image-otros"
            :src="
              require('@/assets/Contravel Archivos/servicios/deportivos.jpg')
            "
            alt="The Buefy Logo"
            style="width: 150px"
            responsive
          ></b-image>
          <b class="has-text-centered mt-4">Eventos deportivos</b>
        </div>
        <div
          class="column otros-child is-flex is-flex-direction-column is-justify-content-center is-align-content-center is-align-items-center"
          @click="obtenerRuta('SIMTRAVEL.pdf')"
        >
          <b-image
            class="image-otros"
            :src="require('@/assets/Contravel Archivos/servicios/sim.jpg')"
            alt="The Buefy Logo"
            style="width: 150px"
            responsive
          ></b-image>
          <b class="has-text-centered mt-4">My Simtravel</b>
        </div>
        <div
          class="column otros-child is-flex is-flex-direction-column is-justify-content-center is-align-content-center is-align-items-center"
          @click="modalVisa = !modalVisa"
        >
          <b-image
            class="image-otros"
            :src="require('@/assets/Contravel Archivos/servicios/visas.jpg')"
            alt="The Buefy Logo"
            style="width: 150px"
            responsive
          ></b-image>
          <b class="has-text-centered mt-4">Visas</b>
        </div>
        <b-modal v-model="otrosInterruptor" v-if="ruta != ''" class="modal-otros">
          <div class="column">
            <div class="box">
              <div class="columns p-0">
                <div class="column is-flex is-justify-content-end p-0">
                  <b-button size="is-small" @click="abrir(ruta)">
                    Abrir en Navegador
                  </b-button>
                </div>
              </div>
              <div class="columns p-0">
                <div
                  class="column is-flex is-justify-content-center is-align-items-center"
                >
                  <b-button
                    icon-left="arrow-left"
                    size="is-small"
                    @click="pages--"
                    :disabled="pages <= 1"
                  ></b-button>
                  <b-tag class="p-4">{{ pages }} / {{ numpages }}</b-tag>
                  <b-button
                    icon-right="arrow-right"
                    size="is-small"
                    @click="pages++"
                    :disabled="pages == numpages"
                  ></b-button>
                </div>
              </div>
              <pdf
                class="is-flex is-justify-content-center pt-0 mt-0"
                v-if="ruta != null"
                :src="ruta"
                :page="pages"
              >
                <template slot="loading">
                  <div class="section is-flex is-justify-content-center">
                    Cargando Contenido, espere un momento....
                  </div>
                </template>
              </pdf>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <div
      class="section mt-0 pt-3 pb-0"
      :style="'margin-top:' + margin + '!important'"
      v-if="images.length > 0"
    >
      <div class="titulo is-flex is-align-items-center p-3 pb-0">
        <div class="ofertas is-flex is-align-items-center">
          <h1 class="subtitle is-3 pr-2">Descubre nuestras</h1>
          <h1 class="title">Ultimas Promociones!</h1>
        </div>
      </div>
      <b-carousel-list
        class="carrusel-list"
        icon-size="is-medium"
        v-model="test"
        :data="images"
        :items-to-show="items"
        :autoplay="true"
        :arrow="images.length > 4"
        style="display: flex; flex-direction: column"
        repeat
      >
        <template #item="image">
          <div
            class="card"
            style="max-width: 100%; border-radius: 15px; height: 100%"
          >
            <div
              class="column"
              v-if="image.oferta == 'true'"
              style="
                width: 100%;
                max-width: 100px;
                padding: 0;
                position: absolute;
                z-index: 1000;
                max-height: 35px;
                height: 100%;
              "
            >
              <div
                class="tag"
                style="
                  width: 100%;
                  height: 100%;
                  background-color: #ff0606;
                  color: #fff;
                  font-size: 13pt;
                  font-weight: bolder;
                "
              >
                ¡OFERTA!
              </div>
            </div>
            <div class="card-image" @click="NavegarDetalles(image.id)">
              <figure class="image is-3by3">
                <a
                  ><img
                    style="
                      max-width: 100%px;
                      max-height: 300px;
                      border-radius: 15px 15px 0px 0px;
                    "
                    :src="server + image.imagen"
                    alt="The Buefy Logo"
                /></a>
              </figure>
            </div>
            <div class="columns pt-2 is-centered mb-0">
              <div class="column pb-0">
                <div class="rows">
                  <div class="row">
                    <h6
                      class="title circuito-title is-6 m-0 has-text-centered pt-1 pr-4 pl-4"
                      style="
                        color: #d89595;
                        font-size: 15px;
                        font-weight: bolder;
                        max-height: 3.6em;
                        white-space: pre-line;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      "
                    >
                      {{ image.titulo }}
                    </h6>
                  </div>
                  <div class="row">
                    <h6
                      class="title circuito-destino is-7 m-0 has-text-centered"
                      style="font-size: 12px; color: #0d1335"
                    >
                      {{ image.destino }}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                class="column mr-4"
                style="
                  position: absolute;
                  right: 0;
                  top: 250px;
                  width: fit-content;
                "
              >
                <div
                  class="columns pt-2 pr-2 pl-2 is-justify-content-center has-text-centered"
                  style="
                    border-radius: 5px;
                    background-color: rgba(78, 78, 78, 0.603);
                  "
                >
                  <b-tooltip
                    label="Incluye Vuelo"
                    class="aereo-tool"
                    v-if="image.avion == 'true'"
                  >
                    <b-icon
                      class="tooltip-circuito mr-2"
                      icon="plane"
                      style="color: white"
                      size="is-medium"
                    />
                  </b-tooltip>
                  <b-tooltip
                    label="Incluye Hospedaje"
                    class="hotel-tool"
                    v-if="image.hotel == 'true'"
                  >
                    <b-icon
                      class="tooltip-circuito mr-2"
                      icon="building-o"
                      style="color: white"
                      size="is-medium"
                    />
                  </b-tooltip>
                  <b-tooltip
                    label="Incluye Traslado"
                    class="traslados-tool"
                    v-if="image.traslado == 'true'"
                  >
                    <b-icon
                      class="tooltip-circuito mr-2"
                      icon="bus"
                      style="color: white"
                      size="is-medium"
                    />
                  </b-tooltip>
                  <b-tooltip
                    label="Incluye Tren"
                    class="tren-tool"
                    v-if="image.train == 'true'"
                  >
                    <b-icon
                      class="tooltip-circuito mr-2"
                      icon="subway"
                      style="color: white"
                      size="is-medium"
                    />
                  </b-tooltip>
                  <b-tooltip
                    label="Incluye Alimentos"
                    class="food-tool"
                    v-if="image.alimentos == 'true'"
                  >
                    <b-icon
                      class="tooltip-circuito mr-2"
                      icon="cutlery"
                      style="color: white"
                      size="is-medium"
                    />
                  </b-tooltip>
                  <b-tooltip
                    label="Incluye Crucero"
                    class="crucero-tool"
                    v-if="image.crucero == 'true'"
                  >
                    <b-icon
                      class="tooltip-circuito mr-2"
                      icon="ship"
                      style="color: white"
                      size="is-medium"
                    />
                  </b-tooltip>
                </div>
              </div>
            </div>
            <div class="row pt-0 pb-0">
              <div class="column pt-0 pb-0 is-flex is-flex-direction-row">
                <div
                  class="column circuito-duracion is-flex is-justify-content-start pt-2 pb-1"
                  style="font-size: 9pt; color: #0d1335"
                >
                  Duracion:&nbsp;<b>{{ image.duracion }}</b>
                </div>
                <div
                  class="column pt-2 pb-1"
                  style="display: flex; flex-direction: column"
                >
                  <div style="display: flex; justify-content: center">
                    <div
                      class="salidas-style"
                      style="display: flex; font-size: 9pt; color: #0d1335"
                    >
                      Salidas:&nbsp;
                      <b style="font-size: 8pt">{{ image.salidas[0] }}</b>
                    </div>
                  </div>
                  <div style="display: flex; justify-content: end">
                    <b-tooltip class="tooltip-fechas ml-5" multilined>
                      <template v-slot:content>
                        <b v-for="(fecha, index) in image.salidas" :key="index"
                          >{{ fecha }}<br
                        /></b>
                      </template>
                      <a id="vermas" style="font-size: 9pt">Mas fechas...</a>
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <hr class="divider m-0 mr-4 ml-4" style="margin: 0" />
            </div>
            <div class="row pt-2 pr-2 pl-2">
              <div style="font-size: 9pt; color: #0d1335">Desde:</div>
              <b style="font-size: 16pt; color: #0d1335" class="ml-3">{{
                image.precio
              }}</b>
            </div>
            <div class="row">
              <div
                class="column m-0 pb-0"
                @click="NavegarDetalles(image.id)"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <b-field grouped>
                  <a id="vermas">Conocer mas...</a>
                </b-field>
                <b-icon
                  icon="arrow-right"
                  size="is-medium"
                  style="color:rgb(0, 112, 171); !important;"
                ></b-icon>
              </div>
            </div>
          </div>
        </template>
      </b-carousel-list>
    </div>
  </div>
</template>
<script>
import Autos from "@/components/Servicios/Autos";
import Asistencia from "@/components/Servicios/Asistencia.vue";
import Black from "@/components/Servicios/Black.vue";
import Trenes from "@/components/Servicios/Trenes.vue";
import pdf from "pdfvuer";
import MainService from "@/services/MainServices";
import Visa from "@/components/Servicios/Visa.vue";

export default {
  components: {
    Autos,
    Asistencia,
    Black,
    Trenes,
    pdf,
    Visa,
  },
  data() {
    return {
      modalTrenes: false,
      modalAutos: false,
      modalAsistencia: false,
      modalBlack: false,
      prueba: false,
      otros: " visuallyhidden hidden",
      otrosInterruptor: false,
      ruta: "",
      pages: 1,
      numpages: 0,
      modalVisa: false,
      images: [],
      test: 0,
      items: 5,
      margin: "130px",
      date: new Date()
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    this.resize();
    this.obtenerUltimasOfertas()
    this.obtenerServidorbackend()
    this.updatePaquetes()
  },
  methods: {
    async updatePaquetes() {
      const respuesta = await MainService.actualizarPaquetes(this.date)
    },
    resize() {
      if (window.innerWidth > 1320 && window.innerWidth < 1620) {
        this.items = 4;
      } else if (window.innerWidth > 1000 && window.innerWidth < 1320) {
        this.items = 3;
      } else if (window.innerWidth > 700 && window.innerWidth < 1000) {
        this.items = 2;
      } else if (window.innerWidth < 700) {
        this.items = 1;
      } else {
        this.items = 5;
      }
    },
    async obtenerServidorbackend() {
      this.server = await MainService.obtenerServer();
    },

    async obtenerUltimasOfertas() {
      const respuesta = await MainService.obtenerUltimasOfertas();
      for (const elemento of respuesta) {
        elemento.salidas = elemento.salidas.split(",");
      }

      this.images = respuesta;
    },
    abrir(url) {
      window.open(url, "_blank");
    },
    async obtenerRuta(name) {
      if (this.otrosInterruptor) {
        this.otrosInterruptor = false;
      }
      this.pages = 1;
      this.otrosInterruptor = true;
      this.ruta = this.server + "/softw/contravel/otros/" + name;
      this.numpages = await this.obtenerNumPaginas();
    },

    async obtenerNumPaginas() {
      const respuesta = await pdf.createLoadingTask(this.ruta);
      return respuesta._pdfInfo.numPages;
    },
    cambiarCss() {
      this.prueba = !this.prueba;
      if (this.prueba !== true) {
        this.otros = " visuallyhidden";
        setTimeout(() => this.cambiarValoresCss(" hidden", "100px"), 600);
      } else {
        this.otros = " visuallyhidden";
        setTimeout(() => this.cambiarValoresCss(" ", "0px"), 50);
      }
    },
    cambiarValoresCss(valor1, valor2) {
      this.margin = valor2;
      if (valor1 == " ") {
        this.otros = valor1;
      } else {
        this.otros += valor1;
      }
    },
    abrirAsistencia(){
      window.open("https://twglobalprotection.com/promo/mexico/contravel/index.html", "_blank");
    },
    clickprueba() {
      this.modalAutos = !this.modalAutos;
    },
    NavegarHacia(name) {
      this.$router.push({
        name: name,
      });
      this.show = false;
    },
    NavegarDetalles(props) {
      window.open(
        "//" + window.location.host + "/detalles/#/" + props,
        "_blank"
      );
    },
  },
};
</script>
<style>
.image-otros {
  transition: transform 0.2s;
}

.otros-child:hover > .image-otros {
  transform: scale(1.3);
}

.otros {
  margin-top: 75px;
  transition: all 0.5s linear !important;
  display: block !important;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  opacity: 0;
}

.hexagon-item:hover .subtitle {
  font-size: 1.3rem !important;
  color: #bf930d !important;
  -webkit-animation: focus-in-contract 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: focus-in-contract 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.hexagon-item:hover .hex-content-inner {
  width: 100rem !important;
  top: 97%;
}

@-webkit-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.operadora.hex2 {
  z-index: 1;
}

ul.operadora li {
  width: 180px;
  margin-left: 2.2rem;
}

ul.operadora {
  list-style: none;
  display: flex;
  position: relative;
  top: 20px;
  left: -30px;
}

ul.operadora.hex1 {
  transform: translateY(19px);
}

/* End of container */
.hexagon-item {
  cursor: pointer;
  width: 200px;
  height: 173.20508px;
  float: left;
  margin-left: -29px;
  z-index: 0;
  position: relative;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}

.hexagon-item:first-child {
  margin-left: 0;
}

.hexagon-item:hover {
  z-index: 1;
}

.hexagon-item:hover .hex-item:last-child {
  opacity: 1;
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

.hexagon-item:hover .hex-item:first-child {
  opacity: 1;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.hexagon-item:hover .hex-item:first-child div:before,
.hexagon-item:hover .hex-item:first-child div:after {
  height: 5px;
}

.hexagon-item:hover .hex-item div::before,
.hexagon-item:hover .hex-item div::after {
  background-color: #01b7ea;
}

.hexagon-item:hover .hex-content svg {
  -webkit-transform: scale(0.97);
  -moz-transform: scale(0.97);
  -ms-transform: scale(0.97);
  -o-transform: scale(0.97);
  transform: scale(1.17);
}

.page-home .hexagon-item:nth-last-child(1),
.page-home .hexagon-item:nth-last-child(2),
.page-home .hexagon-item:nth-last-child(3) {
  -webkit-transform: rotate(30deg) translate(87px, -80px);
  -moz-transform: rotate(30deg) translate(87px, -80px);
  -ms-transform: rotate(30deg) translate(87px, -80px);
  -o-transform: rotate(30deg) translate(87px, -80px);
  transform: rotate(30deg) translate(87px, -80px);
}

.hex-item {
  position: absolute;
  top: 0;
  left: 60px;
  width: 120px;
  height: 210.20508px;
}

.hex-item:first-child {
  z-index: 0;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hex-item:last-child {
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1;
}

.hex-item div {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 121px;
  height: 210.20508px;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
}

.hex-item div::before,
.hex-item div::after {
  /* background-color: #1b6eb6; */
  background-color: #bf930d;
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.hex-item div:before {
  top: 0;
}

.hex-item div:after {
  bottom: 0;
}

.hex-item div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.hex-item div:nth-child(2) {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  transform: rotate(60deg);
}

.hex-item div:nth-child(3) {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  transform: rotate(120deg);
}

.hex-content {
  color: #fff;
  display: block;
  height: 180px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  transform: rotate(-30deg);
  width: 156px;
}

.hex-content .hex-content-inner {
  width: 60%;
  left: 55%;
  position: absolute;
  top: 97%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hex-content .icon {
  display: block;
  font-size: 36px;
  line-height: 30px;
  margin-bottom: 11px;
}

.hex-content .title {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
}

.hex-content svg {
  left: 1px;
  position: absolute;
  top: -9px;
  transform: scale(1.05);
  z-index: -1;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.hex-content:hover {
  color: #fff;
}

@media (max-width: 850px) {
  ul.operadora.hex2,
  ul.operadora.hex1 {
    display: grid;
  }

  ul.operadora li {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
  }
}

.modal-otros .modal-background .modal-content{
  max-width: 1200px !important;
}
</style>