<template>
  <div class="box">
    <b-table
      centered
      :narrowed="true"
      :data="misCapacitaciones"
      :paginated="misCapacitaciones.length > 4"
      per-page="4"
      pagination-rounded
      pagination-size="is-small"
      class="Capacitaciones"
      pagination-order="is-centered"
    >
      <b-table-column label="Titulo" v-slot="props" width="200px">
        {{ props.row.asunto }}
      </b-table-column>
      <b-table-column label="Fecha" v-slot="props" width="190px">
        <b-tag type="is-danger" v-if="props.row.estatus == 'FINALIZADO'"
          >{{ obtenerFecha(props.row.fecha) }}
        </b-tag>
        <b-tag type="is-success" v-if="props.row.estatus == 'ACTIVO'"
          >{{ obtenerFecha(props.row.fecha) }} </b-tag
        ><br />
        <b-tag
          type="is-danger is-light"
          v-if="props.row.estatus == 'FINALIZADO'"
        >
          {{
            obtenerHora(
              new Date(props.row.fecha).getHours(),
              new Date(props.row.fecha).getMinutes()
            )
          }}
        </b-tag>
        <b-tag type="is-success is-light" v-if="props.row.estatus == 'ACTIVO'">
          {{
            obtenerHora(
              new Date(props.row.fecha).getHours(),
              new Date(props.row.fecha).getMinutes()
            )
          }}
        </b-tag>
      </b-table-column>
      <b-table-column label="Duración" v-slot="props" width="100px">
        <b-tag type="is-warning is-light mr-2" icon="clock-o">{{
          props.row.duracion
        }}</b-tag>
      </b-table-column>
      <b-table-column label="Participante" v-slot="props" width="250px">
        <b>{{ props.row.participante }}</b
        ><br />
        <p style="font-size: 9pt">{{ props.row.email }}</p>
      </b-table-column>
      <b-table-column label="Estatus Inscripción" v-slot="props" width="190px">
        <b-tag
          v-if="props.row.status === 'CANCELADO'"
          type="is-danger is-light"
          >{{ props.row.status }}</b-tag
        >
        <b-tag
          v-if="props.row.status === 'REGISTRADO'"
          type="is-success is-light"
          >{{ props.row.status }}
        </b-tag>
      </b-table-column>
      <b-table-column label="Accion" v-slot="props" width="100px">
        {{}}
        <b-field grouped group-multiline>
          <div class="control">
            <b-button
              :disabled="
                props.row.status == 'CANCELADO' ||
                props.row.estatus == 'FINALIZADO'
              "
              type="is-danger"
              class="mr-2"
              size="is-small"
              icon-right="times"
              @click="cancelarRegistro(props.row.id_registro)"
            >
            </b-button>
            <b-button
              :disabled="
                props.row.status == 'CANCELADO' ||
                props.row.estatus == 'FINALIZADO' ||
                new Date(props.row.fecha).getTime() + 1800000 < date.getTime()
              "
              type="is-info"
              class="mr-2"
              size="is-small"
              icon-right="external-link-square"
              @click="abrirUrl(props.row.url)"
            >
            </b-button>
          </div>
        </b-field>
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
import EventBus from "@/EventBus";
import MainService from "@/services/MainServices";
export default {
  props: ["value"],
  data() {
    return {
      misCapacitaciones: [],
      date: new Date(),
    };
  },
  methods: {
    abrirUrl(url) {
      if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
        window.open(url);
      } else {
        window.open("//" + url);
      }
    },
    async cancelarRegistro(id) {
      const respuesta = await MainService.cancelarRegistro(id);
      if (respuesta > 0) {
        this.obtenerMisCapacitaciones();
        this.interruptor = !this.interruptor;
      }
    },
    obtenerMes(mes) {
      if (mes === 1) {
        return "Enero";
      } else if (mes === 2) {
        return "Febrero";
      } else if (mes === 3) {
        return "Marzo";
      } else if (mes === 4) {
        return "Abril";
      } else if (mes === 5) {
        return "Mayo";
      } else if (mes === 6) {
        return "Junio";
      } else if (mes === 7) {
        return "Julio";
      } else if (mes === 8) {
        return "Agosto";
      } else if (mes === 9) {
        return "Septiembre";
      } else if (mes === 10) {
        return "Octubre";
      } else if (mes === 11) {
        return "Noviembre";
      } else if (mes === 12) {
        return "Diciembre";
      }
    },
    obtenerHora(hora, minutos) {
      if (hora <= 9) {
        hora = "0" + hora;
      }
      if (minutos <= 9) {
        minutos = "0" + minutos;
      }
      if (hora > 12) {
        return hora + ":" + minutos + " pm";
      } else {
        return hora + ":" + minutos + " am";
      }
    },
    obtenerFecha(fecha) {
      let mes = new Date(fecha).getMonth() + 1;
      let dia = new Date(fecha).getDate();
      let año = new Date(fecha).getFullYear();

      if (dia < 10) {
        dia = "0" + dia;
      }
      return dia + " de " + this.obtenerMes(mes) + " de " + año;
    },
    async obtenerMisCapacitaciones() {
      const agencia = EventBus.obtenerUsuario()["Reference"];
      const respuesta = await MainService.obtenerMisCapacitaciones(agencia);
      this.misCapacitaciones = respuesta;
    },
  },
  mounted() {
    this.obtenerMisCapacitaciones();
  },
  computed: {
    interruptor: {
      get() {
        console.log(this.value);
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style>
.Capacitaciones .level {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>