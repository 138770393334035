<template>
  <div>
    <HeaderIngresa
      v-if="usuarioLogueado"
      v-model="usuarioLogueado"
      :key="mostrar"
    />
    <router-view />
    <Inicio v-if="usuarioLogueado"></Inicio>
    <b-image
      v-if="usuarioLogueado"
      class="img-footer"
      :src="require('@/assets/footer-image.png')"
    />
    <Footer v-if="usuarioLogueado" />
  </div>
</template>

<script>
import HeaderIngresa from "@/components/Header_ingresa";
import EventBus from "@/EventBus";
import Footer from "@/components/Footer";
import MainService from "@/services/MainServices";
import Inicio from "@/components/Inicio.vue";

export default {
  name: "App",
  metaInfo() {
    return {
      title: "Contravel | Tu socio Inteligente",
    };
  },
  components: {
    Footer,
    HeaderIngresa,
    Inicio,
  },

  data: () => ({
    logueado: false,
    usuarioLogueado: null,
    mostrar: false,
    date: new Date(),
    viewCarrusel: false,
  }),
  updated() {
    if (this.$router.history.current.name === "404") {
      this.usuarioLogueado = null;
      this.mostrar = false;
    } else {
      this.mostrar = true;
    }
  },
  mounted() {
    this.actualizarPaquetes(this.date);
    EventBus.$on("establecerUsuario", (usuario) => {
      this.refrescarUsuario(usuario);
    });
    EventBus.$on("cerrarSesion", () => {
      this.refrescarUsuario({});
    });
    EventBus.$on("navegarHacia", this.navegarHacia);
    EventBus.$on("mostrarMenu", this.mostrarMenu);
    EventBus.$on("ocultarMenu", this.ocultarMenu);
    this.obtenerUsuarioDeLocalStorage();
  },
  created() {
    window.addEventListener("click", this.obtenerRouter);
  },
  destroyed() {
    window.removeEventListener("click", this.obtenerRouter);
  },
  methods: {
    async actualizarPaquetes(date) {
      await MainService.actualizarPaquetes(date);
    },
    refrescarUsuario(usuario) {
      if (usuario.email) {
        this.logueado = true;
        this.usuarioLogueado = usuario;
      } else {
        this.logueado = false;
      }
    },
    obtenerUsuarioDeLocalStorage() {
      const usuario = EventBus.obtenerUsuario();
      this.refrescarUsuario(usuario);
    },
    navegarHacia(nombreRuta) {
      this.$router.push({ name: nombreRuta });
    },
  },
};
</script>
<style>
.img-footer.image {
  position: static;
}
body {
  background-color: #fcfcfc;
}
</style>