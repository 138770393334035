<template>
  <div>
    <b-modal
      v-model="interruptor"
      class="modal-visa"
      style="background-color: transparent"
    >
      <div class="modal-card" style="width: 100%; height: 570px">
        <header
          class="modal-card-head has-text-centered"
          style="background-color: #1b7fb4"
        >
          <p class="modal-card-title has-text-white">VISAS</p>
        </header>
        <section class="modal-card-body p-6">
          <div class="rows">
            <div class="row">
              <b-message type="is-danger" v-if="errorMsg" size="is-small">
                {{ errorMsg }}
              </b-message>
              <b-message
                type="is-success"
                v-if="msg"
                auto-close
                size="is-small"
              >
                {{ msg }}
              </b-message>
            </div>
          </div>
          <div class="rows mt-4">
            <div class="row">
              <div class="columns">
                <div class="column">
                  <b-field
                    class="mt-3"
                    label="Nombre Completo"
                    label-position="on-border"
                  >
                    <b-input
                      size="is-small"
                      ref="Vnombre"
                      v-model="nombre"
                      required
                      rounded
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    class="mt-3"
                    label="Email"
                    label-position="on-border"
                  >
                    <b-input
                      size="is-small"
                      ref="Vmail"
                      v-model="email"
                      required
                      rounded
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="columns">
                <div class="column">
                  <b-field
                    class="mt-3"
                    label="Telefono"
                    label-position="on-border"
                  >
                    <b-input
                      size="is-small"
                      ref="Vtelefono"
                      v-model="telefono"
                      required
                      rounded
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    class="mt-3"
                    label="Nacionalidad"
                    label-position="on-border"
                  >
                    <b-input
                      size="is-small"
                      ref="Vnacionalidad"
                      v-model="nacionalidad"
                      required
                      rounded
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="columns">
                <div class="column">
                  <b-field
                    class="mt-3"
                    label="Residencía"
                    label-position="on-border"
                  >
                    <b-input
                      size="is-small"
                      ref="Vresidencia"
                      v-model="residencia"
                      required
                      rounded
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    class="mt-3"
                    label="Pais de Origen"
                    label-position="on-border"
                  >
                    <b-input
                      size="is-small"
                      ref="Vorigen"
                      v-model="origen"
                      required
                      rounded
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="columns">
                <div class="column">
                  <b-field
                    class="mt-3"
                    label="Pais de Destino"
                    label-position="on-border"
                  >
                    <b-input
                      size="is-small"
                      ref="Vdestino"
                      v-model="destino"
                      required
                      rounded
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    class="mt-3"
                    label="Pasaporte de nacionalidad"
                    label-position="on-border"
                  >
                    <b-input
                      size="is-small"
                      ref="Vpasaporte"
                      v-model="pasaporte"
                      required
                      rounded
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="columns">
                <div class="column">
                  <b-field
                    class="mt-3"
                    label="Fecha Salida"
                    label-position="on-border"
                  >
                    <b-datepicker
                      append-to-body
                      position="is-top-right"
                      size="is-small"
                      ref="Vsalida"
                      v-model="salida"
                      required
                      rounded
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    class="mt-3"
                    label="Fecha de retorno"
                    label-position="on-border"
                  >
                    <b-datepicker
                      append-to-body
                      position="is-top-right"
                      size="is-small"
                      ref="Vretorno"
                      v-model="retorno"
                      required
                      rounded
                    ></b-datepicker>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="columns">
                <div class="column has-text-centered">
                  <p class="contacto">
                    <b>Contacta a {{ dataVisa.nombre }} :</b><br />
                    Tel. {{ dataVisa.telefono }}<br />
                    Cel. {{ dataVisa.celular }}<br />
                    <a
                      @click="descargar(dataVisa.archivo)"
                      target="_blank"
                      class="archivoStyle"
                      >Descargar Detalles</a
                    >
                  </p>
                </div>
                <div
                  class="column is-flex is-justify-content-center is-align-items-center"
                >
                  <b-button
                    size="is-small"
                    :loading="loading"
                    style="width: 100%"
                    @click="enviarSolicitudVisa()"
                    >Enviar</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Constantes from "@/Constantes";
import MailService from "@/services/MailService";
import MainService from "@/services/MainServices";
import ServicioService from "@/services/ServicioService";

export default {
  props: ["value"],
  data() {
    return {
      nombre: null,
      telefono: null,
      email: null,
      nacionalidad: null,
      residencia: null,
      origen: null,
      destino: null,
      pasaporte: null,
      salida: null,
      retorno: null,
      server: null,
      dataVisa: [],
      errorMsg: null,
      loading: false,
      msg: null,
    };
  },
  beforeMount() {
    this.obtenerRutaServidor();
    this.obtenerInfoVisa();
  },
  methods: {
    async enviarSolicitudVisa() {
      const fields = [
        "Vnombre",
        "Vmail",
        "Vnacionalidad",
        "Vresidencia",
        "Vorigen",
        "Vdestino",
        "Vdestino",
        "Vpasaporte",
        "Vsalida",
        "Vretorno",
      ];
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return;
        }
      }
      this.errorMsg = null;
      this.loading = true;
      const respuesta = await MailService.sendMailVisa(
        this.nombre,
        this.email,
        this.telefono,
        this.nacionalidad,
        this.residencia,
        this.origen,
        this.destino,
        this.pasaporte,
        this.salida,
        this.retorno
      );
      if (respuesta) {
        this.nombre = null;
        this.email = null;
        this.telefono = null;
        this.nacionalidad = null;
        this.residencia = null;
        this.origen = null;
        this.destino = null;
        this.pasaporte = null;
        this.salida = null;
        this.retorno = null;
        this.loading = false;
        this.msg = "Solicitud enviada con exito!";
      } else {
        this.errorMsg = respuesta;
        this.loading = false;
      }
    },
    async obtenerRutaServidor() {
      this.server = await MainService.obtenerServer();
    },
    async obtenerInfoVisa() {
      this.dataVisa = await ServicioService.obtenerVisa();
    },
    descargar(url) {
      window.open(
        Constantes.URL_SERVIDOR + "/descargar.php?url=" + url,
        "_blank"
      );
    },
  },
  computed: {
    interruptor: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style>
.contacto {
  color: #132f42;
}

.archivoStyle {
  color: #132f42;
}
.archivoStyle:hover {
  color: #b15e10;
}
</style>