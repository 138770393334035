<template>
  <div>
    <b-modal v-model="interruptor" width="70%">
      <div class="modal-card" style="width: 100%">
        <header
          class="modal-card-head has-text-centered"
          style="background-color: #1b7fb4"
        >
          <p class="modal-card-title has-text-white">Renta de Auto</p>
        </header>
        <section class="modal-card-body mb-6">
          <iframe
            title="autos"
            src="https://subsite.agentcars.com/es/site/form-iframe?agency=contravel&form=3"
            style="height: 200px; width: 100%"
          ></iframe>
        </section>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {};
  },
  computed: {
    interruptor: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>