import Vue from "vue"
import App from "./App"
import Meta from "vue-meta"
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import router from "./router";
import "@mdi/font/css/materialdesignicons.css";
import "font-awesome/css/font-awesome.css";
import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import {  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add();
Vue.component('vue-fontawesome', FontAwesomeIcon);

// ...

Vue.use(Buefy, {
  defaultIconPack: 'fa'
});
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false;


new Vue({
  render: (h) => h(App),
  router: router,
}).$mount("#app");
