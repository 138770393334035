<template>
  <div>
    <div  id="BInicio" >
      <a aria-label="menu" @click="show = !show">
        <b-tooltip class="tooltip-whats" label="Contactanos" position="is-left" multilined>
            <div class="whatsapp-button" @click="openWhatsApp()" />
      </b-tooltip>
      </a>
    </div>
  </div>
</template>

<script>
import EventBus from "@/EventBus";
import LoginServices from "@/services/LoginServices";

export default {
  name: "HelloWorld",
  data() {
    return {
      showTooltip: false,
    }
  },
  mounted() {
    this.obtenerInfoUser()
  },
  methods: {
        // ... código existente ...

        showMessage() {
      this.showTooltip = true;
    },
    hideMessage() {
      this.showTooltip = false;
    },
    async obtenerInfoUser() {
      if (this.$route.meta === "pre") {
        const respuesta = await LoginServices.obtenerInfoUser(this.$route.params)
        if (respuesta.code === 103) {
          EventBus.eliminarUsuario()
          this.navegarHacia("404")
        } else {
          EventBus.establecerUsuario(respuesta)
          this.navegarHacia("Operadora")
        }
      }
    },
    navegarHacia(nombreRuta) {
      this.$router.push({ name: nombreRuta });
    },
    openWhatsApp() {
      // Agrega aquí la lógica para abrir WhatsApp, por ejemplo:
      window.open('https://api.whatsapp.com/send?phone=5568188424', '_blank');
    }
  }
};
</script>

<style>
#BInicio a {
  bottom: 1vw;
  right: 1vw;
  position: fixed;
  z-index: 9999;
}
.whatsapp-button {
  z-index: 100000000px;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #4caf50;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;

}

.whatsapp-button:hover {
  background-color: rgb(255, 255, 255);
  border: 2px solid #4caf50;
  background-image: url("@/assets/whats_white.png");
  transform: scale(1.3);
}

.whatsapp-button::before {
  content: "";
  /* Para agregar un ícono utilizando pseudo-elemento ::before */
  display: block;
  width: 50px;
  /* Ajusta el ancho del ícono */
  height: 50px;
  /* Ajusta la altura del ícono */
  background-image: url("@/assets/whats_logo.png");
  /* Cambia la ruta y el nombre del archivo del ícono */
  background-size: cover;
}


.whatsapp-tooltip {
  position: absolute;
  bottom: 0px;
  right: 20px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 14px;
  border-radius: 4px;
  pointer-events: none; /* Evita que el mensaje afecte a los eventos del botón */
  opacity: 0; /* Inicialmente, el mensaje estará oculto */
  transition: opacity 0.2s ease;
}

.whatsapp-button:hover + .whatsapp-tooltip {
  /* Cuando el botón esté en hover, muestra el mensaje */
  opacity: 1;
}

.tooltip-whats .tooltip-content {
  margin-right: 10px !important;
  font-size: 12pt !important;
  background-color: white !important;
  color: #4caf50 !important;
  border: 1px solid #4caf50 ;
  width: 140px !important;
}

.tooltip-whats .tooltip-content::before {
 border-left-color: #4caf50 !important;
}
</style>
