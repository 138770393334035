<template>
  <div class="section m-6">
    <div style="width: 100%">
      <div class="titulo is-flex is-align-items-center pl-6 pb-0">
        <div class="ofertas is-flex is-align-items-center">
          <h1 class="subtitle is-3 pr-2">
            Descubre nuestros <strong>Servicios de Circuitos!</strong>
          </h1>
        </div>
      </div>
    </div>
    <div>
      <div
        class="columns m-5 menuOperadora is-flex is-flex-direction-row is-justify-content-center"
      >
        <div class="column operadoraBoton" style="width: 100%">
          <b-dropdown :triggers="[trigger]" aria-role="list" expanded>
            <template #trigger>
              <b-button
                style="width: 100%"
                class="pr-5 pl-5"
                @click="obtenerCircuitos()"
                type="is-info"
                >Ver todo</b-button
              >
            </template>
          </b-dropdown>
        </div>
        <div
          class="column operadoraBoton"
          v-if="disney.length != 0"
          style="width: 100%"
        >
          <b-dropdown :triggers="[trigger]" aria-role="list" expanded>
            <template #trigger>
              <b-button
                style="width: 100%"
                class="pr-5 pl-5"
                label="Disney"
                @click="obtenerPorRegiones('Circuito', 'Disney')"
                type="is-info"
              />
            </template>
            <b-dropdown-item
              v-for="(pais, index) in disney"
              :key="index"
              @click="obtenerPorPais(pais.pais)"
              >{{ pais.pais }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div
          class="column operadoraBoton"
          v-if="mexico.length != 0"
          style="width: 100%"
        >
          <b-dropdown
            class="dropAccion"
            :triggers="[trigger]"
            aria-role="list"
            expanded
          >
            <template #trigger>
              <b-button
                style="width: 100%"
                class="pr-5 pl-5"
                label="México"
                @click="obtenerPorRegiones('Circuito', 'Mexico')"
                type="is-info"
              />
            </template>
            <b-dropdown-item
              v-for="(pais, index) in mexico"
              :key="index"
              @click="obtenerPorPais(pais.pais)"
              >{{ pais.pais }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div
          class="column operadoraBoton"
          v-if="norteAmerica.length != 0"
          style="width: 100%"
        >
          <b-dropdown :triggers="[trigger]" aria-role="list" expanded>
            <template #trigger>
              <b-button
                style="width: 100%"
                class="pr-4 pl-4"
                label="EUA y Canada"
                @click="obtenerPorRegiones('Circuito', 'EUA,Canada')"
                type="is-info"
              />
            </template>
            <b-dropdown-item
              v-for="(pais, index) in norteAmerica"
              :key="index"
              @click="obtenerPorPais(pais.pais)"
              >{{ pais.pais }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div
          class="column operadoraBoton"
          v-if="sudAmerica.length != 0"
          style="width: 100%"
        >
          <b-dropdown :triggers="[trigger]" aria-role="list" expanded>
            <template #trigger>
              <b-button
                style="width: 100%"
                class="pr-4 pl-4"
                label="Caribe y Sudamerica"
                @click="obtenerPorRegiones('Circuito', 'Caribe,Sudamerica')"
                type="is-info"
              />
            </template>
            <b-dropdown-item
              v-for="(pais, index) in sudAmerica"
              :key="index"
              @click="obtenerPorPais(pais.pais)"
              >{{ pais.pais }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div
          class="column operadoraBoton"
          v-if="europa.length != 0"
          style="width: 100%"
        >
          <b-dropdown :triggers="[trigger]" aria-role="list" expanded>
            <template #trigger>
              <b-button
                style="width: 100%"
                class="pr-5 pl-5"
                label="Europa"
                @click="obtenerPorRegiones('Circuito', 'Europa')"
                type="is-info"
              />
            </template>
            <b-dropdown-item
              v-for="(pais, index) in europa"
              :key="index"
              @click="obtenerPorPais(pais.pais)"
              >{{ pais.pais }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div
          class="column operadoraBoton"
          v-if="africa.length != 0"
          style="width: 100%"
        >
          <b-dropdown :triggers="[trigger]" aria-role="list" expanded>
            <template #trigger>
              <b-button
                style="width: 100%"
                class="pr-4 pl-4"
                label="Africa y Medio Oriente"
                @click="obtenerPorRegiones('Circuito', 'Africa,MedioOri')"
                type="is-info"
              />
            </template>
            <b-dropdown-item
              v-for="(pais, index) in africa"
              :key="index"
              @click="obtenerPorPais(pais.pais)"
              >{{ pais.pais }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div
          class="column operadoraBoton"
          v-if="asia.length != 0"
          style="width: 100%"
        >
          <b-dropdown :triggers="[trigger]" aria-role="list" expnaded>
            <template #trigger>
              <b-button
                style="width: 100%"
                class="pr-4 pl-4"
                label="Asia y Oceania"
                @click="obtenerPorRegiones('Circuito', 'Asia,Oceania')"
                type="is-info"
              />
            </template>
            <b-dropdown-item
              v-for="(pais, index) in asia"
              :key="index"
              @click="obtenerPorPais(pais.pais)"
              >{{ pais.pais }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="column operadoraBoton" style="width: 100%">
          <b-dropdown :triggers="[trigger]" aria-role="list" expanded>
            <template #trigger>
              <b-button
                style="width: 100%"
                class="pr-4 pl-4"
                label="Catálogo de Digitales"
                type="is-info"
              />
            </template>
            <b-dropdown-item
              aria-role="listitem"
              v-for="item in catalogo"
              :key="item.id"
            >
              <a :href="item.url" target="_blank" style="text-decoration: none">
                <div class="columns is-centered">
                  <div
                    class="column is-flex is-align-items-center is-justify-content-center"
                  >
                    <figure
                      class="image is-48x48 is-flex is-align-items-center is-justify-content-center"
                    >
                      <img alt="img" :src="server + item.image" />
                    </figure>
                  </div>
                  <div class="column is-flex is-align-items-center">
                    {{ item.nombre }}
                  </div>
                </div>
              </a>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="column is-flex is-justify-content-center">
      <b-pagination
        v-if="circuitos.length > 2"
        size="is-small"
        order="is-centered"
        style="width: auto"
        :total="circuitos.length"
        :current.sync="current"
        :per-page="2"
        rounded
        @change="cambiarPages(current)"
      >
      </b-pagination>
    </div>
    <div
      v-if="circuitos.length <= 0 && isEmpty == false"
      class="columns is-centered"
      v-for="(item, index) in 2"
      :key="index"
    >
      <div class="column is-one-fifth" v-for="(item, index) in 4" :key="index">
        <div class="box">
          <figure class="media-center">
            <p class="image">
              <b-skeleton width="100%" height="200"></b-skeleton>
            </p>
          </figure>
          <div class="media-content">
            <div class="content content-operadora">
              <p>
                <b-skeleton active></b-skeleton>
                <b-skeleton height="80px" width="100%"></b-skeleton>
              </p>
            </div>
            <nav class="level is-mobile">
              <div class="level-left">
                <a class="level-item">
                  <b-skeleton width="100"></b-skeleton>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div
      class="columns circuitos-pack is-centered pr-6 pl-6"
      v-for="(circuito, index) in pageArray"
      :key="index"
    >
      <div
        class="column paquete-circuito"
        v-for="(data, index) in circuito"
        :key="index"
      >
        <div
          class="column"
          v-if="data.oferta == 'true'"
          style="
            width: 100%;
            max-width: 100px;
            padding: 0;
            position: absolute;
            z-index: 1000;
            max-height: 35px;
            height: 100%;
          "
        >
          <div
            class="tag"
            style="
              width: 100%;
              height: 100%;
              background-color: #ff0606;
              color: #fff;
              font-size: 13pt;
              font-weight: bolder;
            "
          >
            ¡OFERTA!
          </div>
        </div>
        <div
          class="card"
          style="max-width: 300px; border-radius: 15px; height: 100%"
        >
          <div class="card-image" @click="NavegarHacia(data.id)">
            <figure class="image is-3by3">
              <a
                ><img
                  style="
                    max-width: 300px;
                    max-height: 300px;
                    border-radius: 15px 15px 0px 0px;
                  "
                  alt="img"
                  :src="server + data.imagen"
              /></a>
            </figure>
          </div>
          <div class="columns pt-2 is-centered mb-0">
            <div class="column pb-0">
              <div class="rows">
                <div class="row">
                  <h6
                    class="title circuito-title is-6 m-0 has-text-centered pt-1 pr-4 pl-4"
                    style="
                      color: #d89595;
                      font-size: 15px;
                      font-weight: bolder;
                      max-height: 3.6em;
                      white-space: pre-line;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    "
                  >
                    {{ data.titulo }}
                  </h6>
                </div>
                <div class="row">
                  <h6
                    class="title circuito-destino is-7 m-0 has-text-centered"
                    style="font-size: 12px; color: #0d1335"
                  >
                    {{ data.destino }}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div
            class="column mr-4"
            style="position: absolute; right: 0; top: 250px; width: fit-content"
          >
            <div
              class="columns pt-2 pr-2 pl-2 is-justify-content-center has-text-centered"
              style="
                border-radius: 5px;
                background-color: rgba(78, 78, 78, 0.603);
              "
            >
              <b-tooltip
                label="Incluye Vuelo"
                class="aereo-tool"
                v-if="data.avion == 'true'"
              >
                <b-icon
                  class="tooltip-circuito mr-2"
                  icon="plane"
                  style="color: white"
                  size="is-medium"
                />
              </b-tooltip>
              <b-tooltip
                label="Incluye Hospedaje"
                class="hotel-tool"
                v-if="data.hotel == 'true'"
              >
                <b-icon
                  class="tooltip-circuito mr-2"
                  icon="building-o"
                  style="color: white"
                  size="is-medium"
                />
              </b-tooltip>
              <b-tooltip
                label="Incluye Traslado"
                class="traslados-tool"
                v-if="data.traslado == 'true'"
              >
                <b-icon
                  class="tooltip-circuito mr-2"
                  icon="bus"
                  style="color: white"
                  size="is-medium"
                />
              </b-tooltip>
              <b-tooltip
                label="Incluye Tren"
                class="tren-tool"
                v-if="data.train == 'true'"
              >
                <b-icon
                  class="tooltip-circuito mr-2"
                  icon="subway"
                  style="color: white"
                  size="is-medium"
                />
              </b-tooltip>
              <b-tooltip
                label="Incluye Alimentos"
                class="food-tool"
                v-if="data.alimentos == 'true'"
              >
                <b-icon
                  class="tooltip-circuito mr-2"
                  icon="cutlery"
                  style="color: white"
                  size="is-medium"
                />
              </b-tooltip>
              <b-tooltip
                label="Incluye Crucero"
                class="crucero-tool"
                v-if="data.crucero == 'true'"
              >
                <b-icon
                  class="tooltip-circuito mr-2"
                  icon="ship"
                  style="color: white"
                  size="is-medium"
                />
              </b-tooltip>
            </div>
          </div>
          <div class="row pt-0 pb-0">
            <div class="column pt-0 pb-0 is-flex is-flex-direction-row">
              <div
                class="column circuito-duracion is-flex is-justify-content-start pt-2 pb-1"
                style="font-size: 9pt; color: #0d1335"
              >
                Duracion:&nbsp;<b>{{ data.duracion }}</b>
              </div>
              <div
                class="column pt-2 pb-1"
                style="display: flex; flex-direction: column"
              >
                <div style="display: flex; justify-content: center">
                  <div
                    class="salidas-style"
                    style="display: flex; font-size: 9pt; color: #0d1335"
                  >
                    Salidas:&nbsp;
                    <b style="font-size: 8pt">{{ data.salidas[0] }}</b>
                  </div>
                </div>
                <div style="display: flex; justify-content: end">
                  <b-tooltip class="tooltip-fechas ml-5" multilined>
                    <template v-slot:content>
                      <b v-for="(fecha, index) in data.salidas" :key="index"
                        >{{ fecha }}<br
                      /></b>
                    </template>
                    <a id="vermas" style="font-size: 9pt">Mas fechas...</a>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <hr class="divider m-0 mr-4 ml-4" style="margin: 0" />
          </div>
          <div class="row pt-2 pr-2 pl-2">
            <div style="font-size: 9pt; color: #0d1335">Desde:</div>
            <b style="font-size: 16pt; color: #0d1335" class="ml-3">{{
              data.precio
            }}</b>
          </div>
          <div class="row">
            <div
              class="column m-0 pb-0"
              @click="NavegarHacia(data.id)"
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <b-field grouped>
                <a id="vermas">Conocer mas...</a>
              </b-field>
              <b-icon
                icon="arrow-right"
                size="is-medium"
                style="color:rgb(0, 112, 171); !important;"
              ></b-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="circuitos.length <= 0">
      <div class="has-text-centered">
        ¡Por el momento no tenemos circuitos, vuelve pronto!
      </div>
    </template>
    <div class="column is-flex is-justify-content-center">
      <b-pagination
        type="is-warning"
        v-if="circuitos.length > 2"
        size="is-small"
        order="is-centered"
        :total="circuitos.length"
        :current.sync="current"
        :per-page="2"
        rounded
        @change="cambiarPages(current)"
      >
      </b-pagination>
    </div>
  </div>
</template>
<script>
import MainService from "@/services/MainServices";
import ServicioService from "@/services/ServicioService";
export default {
  data() {
    return {
      circuitos: [],
      server: null,
      pageArray: [],
      current: 1,
      isEmpty: true,
      trigger: null,
      mexico: [],
      norteAmerica: [],
      sudAmerica: [],
      europa: [],
      africa: [],
      asia: [],
      disney: [],
      catalogo: [],
      date: new Date()
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    this.resize();
    this.obtenerCircuitos();
    this.obtenerServidorbackend();
    this.obtenerPaises();
    this.obtenerCatalogo();
    this.updatePaquetes()
  },
  methods: {
    async updatePaquetes() {
      const respuesta = await MainService.actualizarPaquetes(this.date)
    },
    formatoFechas(dataFechas) {
      let text = "";
      for (const fecha of dataFechas) {
        text += fecha + "\n";
      }

      return text;
    },
    async obtenerCatalogo() {
      const respuesta = await MainService.obtenerCatalogo();
      this.catalogo = respuesta;
    },
    cambiarPages(page) {
      this.pageArray = this.circuitos.slice(page + page - 2, page + page);
    },
    resize() {
      if (window.innerWidth < 990) {
        this.trigger = "click";
      } else {
        this.trigger = "hover";
      }
    },
    async obtenerPorPais(pais) {
      const respuesta = await MainService.obtenerPorPais(pais, "Circuito");
      if (respuesta.length > 0) {
        this.isEmpty = false;
      }
      for (const dato of respuesta) {
        dato.salidas = dato.salidas.split(",");
      }

      this.circuitos = this.dividir(respuesta, 4);
      this.pageArray = this.circuitos.slice(0, 2);
    },
    async obtenerPaises(paises) {
      this.mexico = await this.obtenerPais("Circuito", "Mexico");
      this.norteAmerica = await this.obtenerPais("Circuito", "EUA,Canada");
      this.sudAmerica = await this.obtenerPais("Circuito", "Caribe,Sudamerica");
      this.europa = await this.obtenerPais("Circuito", "Europa");
      this.africa = await this.obtenerPais("Circuito", "Africa,MedioOri");
      this.asia = await this.obtenerPais("Circuito", "Asia,Oceania");
      this.disney = await this.obtenerPais("Circuito", "Disney");
    },
    async obtenerPais(tipo, pais) {
      let array = pais.split(",");
      const respuesta = await MainService.obtenerPais(tipo, array);
      return respuesta;
    },
    async obtenerPorRegiones(tipo, region) {
      this.isEmpty = true;
      let array = region.split(",");
      const respuesta = await MainService.obtenerPorRegion(tipo, array);
      if (respuesta.length > 0) {
        this.isEmpty = false;
      }
      for (const dato of respuesta) {
        dato.salidas = dato.salidas.split(",");
      }

      this.circuitos = this.dividir(respuesta, 4);
      this.pageArray = this.circuitos.slice(0, 2);
    },
    async obtenerCircuitos() {
      const respuesta = await ServicioService.obtenerCircuitos();
      if (respuesta.length > 0) {
        this.isEmpty = false;
      }
      for (const dato of respuesta) {
        dato.salidas = dato.salidas.split(",");
      }

      this.circuitos = this.dividir(respuesta, 4);
      this.pageArray = this.circuitos.slice(0, 2);
    },
    dividir(arr, size) {
      let chunk = [],
        i; // declara array vacio e indice de for
      for (
        i = 0;
        i <= arr.length - 1;
        i += size // loop que recorre el array
      )
        chunk.push(arr.slice(i, i + size));
      return chunk;
    },
    async obtenerServidorbackend() {
      this.server = await MainService.obtenerServer();
    },
    NavegarHacia(props) {
      window.open(
        "//" + window.location.host + "/detalles/#/" + props,
        "_blank"
      );
    },
  },
};
</script>
<style>
.tooltip-fechas .tooltip-content {
  background-color: white !important;
  color: rgb(61, 115, 196) !important;
}

.content .content-operadora {
  flex: 1;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.image-stilo {
  border-radius: 20px 20px 0px 0px;
  height: 340px !important;
}

.aereo-tool .tooltip-content {
  background-color: rgba(255, 255, 255, 0.921) !important;
  color: hsl(222, 76%, 45%) !important;
}

.hotel-tool .tooltip-content {
  background-color: hsla(280, 100%, 21%, 0.866) !important;
}

.traslados-tool .tooltip-content {
  background-color: hsla(10, 98%, 41%, 0.789) !important;
}

.tren-tool .tooltip-content {
  background-color: hsla(42, 56%, 40%, 0.749) !important;
}

.food-tool .tooltip-content {
  background-color: hsla(54, 98%, 50%, 0.863) !important;
  color: hsl(222, 76%, 45%) !important;
}

.crucero-tool .tooltip-content {
  background-color: hsla(222, 76%, 45%, 0.749) !important;
}

.paquete-circuito {
  max-width: 22%;
}

.menuOperadora {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 10%,
    rgba(246, 185, 94, 0.432) 50%,
    rgba(255, 255, 255, 0) 90%
  );
}

.operadoraBotonReturn .dropdown .dropdown-trigger .button:active {
  background-color: rgba(250, 62, 0, 0.699) !important;
}

.operadoraBotonReturn .dropdown .dropdown-trigger .button {
  background-color: rgb(61, 115, 196) !important;
}

.operadoraBoton .dropdown .dropdown-trigger .button:focus {
  background-color: rgba(250, 62, 0, 0.699) !important;
}

.operadoraBoton .dropdown .dropdown-trigger .button {
  background-color: rgb(61, 115, 196) !important;
}

.operadoraBoton
  .dropdown
  .dropdown-menu
  .dropdown-content
  .dropdown-item:focus {
  background-color: rgb(255, 238, 211) !important;
}

.operadoraBoton .dropdown .dropdown-menu .dropdown-content .dropdown-item,
.operadoraBoton .dropdown .dropdown-menu .dropdown-content .dropdown-item a {
  color: rgb(61, 115, 196) !important;
}
.operadoraBoton .dropdown-menu{
  z-index: 1000000;
}
@media (max-width: 950px) {
  .menuOperadora {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(190px, 10fr)) !important;
  }
}

@media (max-width: 1500px) {
  .circuitos-pack {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(300px, 3fr)) !important;
  }

  .paquete-circuito {
    max-width: 90% !important;
  }
}

@media (max-width: 1540px) {
  .paquete-circuito {
    max-width: 26%;
  }
}

.menuOperadora {
  background: linear-gradient(
    deg,
    rgba(246, 185, 94, 0.432) 10%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0) 90%
  );
}

#vermas {
  color: rgb(0, 112, 171);
}

#vermas:hover {
  color: rgb(235, 141, 0);
}
</style>